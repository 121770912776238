/*
 *
 * IndustryText constants
 *
 */

export const GET_INDUSTRY = 'IndustryText/GET_INDUSTRY';
export const GET_INDUSTRY_SUCCESS = 'IndustryText/GET_INDUSTRY_SUCCESS';
export const GET_INDUSTRY_ERROR = 'IndustryText/GET_INDUSTRY_ERROR';
export const CLEAR_INDUSTRY = 'IndustryText/CLEAR_INDUSTRY';
