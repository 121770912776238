/* eslint-disable react/jsx-no-useless-fragment */
/**
 *
 * PageLayout
 *
 */

import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@mamacrowd/ui-kit';
import { Divider, Grid } from '@mui/material';

import Box from 'components/Box';
import LazyLoadImage from 'components/LazyLoadImage';

import ENUMPageLayout from 'utils/Enums/PageLayout';

import { useStyle } from './PageLayout.style';

interface IPageLayoutProps {
  title?: string | ReactElement;
  subTitle?: string | ReactElement;
  maxWidth?: 'thin' | 'large';
  coverMaxHeight?: number;
  coverImage?: string | ReactElement;
  image?: string | ReactElement;
  button?: ReactElement | null;
  action?: ReactElement | null;
  type?: ENUMPageLayout;
  stroke?: boolean;
  children?: any;
}

function PageLayout({
  title,
  subTitle,
  maxWidth = 'large',
  coverMaxHeight,
  coverImage,
  image,
  button,
  action,
  type = ENUMPageLayout.simple,
  stroke = false,
  children,
}: IPageLayoutProps) {
  const intl = useIntl();
  const classes = useStyle({
    maxWidth,
    coverMaxHeight,
    hasIcon: type === ENUMPageLayout.withIcon,
    hasTitle: type !== ENUMPageLayout.noTitle,
  });
  let alt: string | undefined = '';
  if (type !== ENUMPageLayout.simple) {
    alt =
      title && typeof title !== 'string'
        ? intl.formatMessage(title.props.messages[title.props.messageId])
        : title;
  }

  return (
    <>
      {coverImage && type === ENUMPageLayout.withCover && (
        <>
          {typeof coverImage === 'string' ? (
            <LazyLoadImage
              src={coverImage}
              alt={alt}
              className={classes.coverImage}
            />
          ) : (
            coverImage
          )}
        </>
      )}
      <Box className={classes.pageLayout}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          {type !== ENUMPageLayout.noTitle && (
            <Grid
              item
              xs={12}
              md={type === ENUMPageLayout.withAction ? 'auto' : 12}
            >
              <Grid container spacing={2}>
                {image && type === ENUMPageLayout.withIcon && (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className={classes.imageContainer}
                    component="div"
                  >
                    {typeof image === 'string' ? (
                      <LazyLoadImage
                        src={image}
                        alt={alt}
                        className={classes.image}
                        transparentBgWhenLoaded
                      />
                    ) : (
                      image
                    )}
                  </Grid>
                )}
                <Grid item className={classes.titleContainer}>
                  <Grid container spacing={2}>
                    {title && (
                      <Grid item xs={12}>
                        <Typography variant="h1" color="textPrimary">
                          {title}
                        </Typography>
                      </Grid>
                    )}
                    {subTitle && (
                      <Grid item xs={12}>
                        <Typography color="textPrimary">{subTitle}</Typography>
                      </Grid>
                    )}
                    {button && (
                      <Grid item xs={12}>
                        {button}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {type === ENUMPageLayout.withAction && action && (
            <Grid item xs={12} md="auto" className={classes.pageLayoutAction}>
              {action}
            </Grid>
          )}
          {stroke && (
            <Grid item xs={12}>
              <Divider className={classes.stroke} />
            </Grid>
          )}
          {children && (
            <Grid item xs={12}>
              <div className={classes.childrenContainer}>{children}</div>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default PageLayout;
