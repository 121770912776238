export enum EnumPageLayout {
  simple = 'PageLayoutSimple',
  withIcon = 'PageLayoutWithIcon',
  withCover = 'PageLayoutWithCover',
  withAction = 'PageLayoutWithAction',
  landing = 'PageLayoutLanding',
  noTitle = 'PageLayoutNoTitle',
}

export default EnumPageLayout;
