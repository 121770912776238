/**
 *
 * ApplyLeadStep2Preview
 *
 */

import { Grid } from '@mui/material';
import { Typography } from '@mamacrowd/ui-kit';

import FormattedMessage from 'components/FormattedMessage';

import { ApplyLeadStep2 } from 'types/Application';

import messages from './messages';

export interface ApplyLeadStep2PreviewProps {
  leadStep2?: ApplyLeadStep2;
}

export function ApplyLeadStep2Preview({
  leadStep2,
}: ApplyLeadStep2PreviewProps) {
  const getLinkedInJSX = (
    linkedin:
      | string
      | { age?: string; name: string; surname: string; linkedinURL?: string }[],
  ) => {
    if (typeof linkedin === 'string') {
      return <b>{linkedin}</b>;
    } else if (Array.isArray(linkedin) && linkedin.length > 0) {
      return linkedin.map(item => (
        <>
          <Typography variant="body1" color="textPrimary" component="span">
            <b>
              - {item.name} {item.surname} {item.age || null}{' '}
              {item.linkedinURL && (
                <a href={item.linkedinURL} target="_blank">
                  {item.linkedinURL}
                </a>
              )}
            </b>
          </Typography>
          <br />
        </>
      ));
    }
    return <b>'-'</b>;
  };
  return leadStep2 ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage messages={messages} messageId="founders" />
          <b>{leadStep2?.founders || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="storia_ed_esperienza_dei_fondatori"
          />
          <br />
          <b>{leadStep2?.storia_ed_esperienza_dei_fondatori || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="coinvolgimento_e_dedizione_a_tempo_pieno_dei_fondatori_nel_progetto"
          />
          <br />
          <b>
            {leadStep2?.coinvolgimento_e_dedizione_a_tempo_pieno_dei_fondatori_nel_progetto ||
              '-'}
          </b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="descrizione_del_team_founder_completa"
          />
          <br />
          <b>{leadStep2?.descrizione_del_team_founder_completa || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="linkedin_profili_dei_founder_e_key_people_del_team"
          />
          <br />
          {getLinkedInJSX(
            leadStep2?.linkedin_profili_dei_founder_e_key_people_del_team,
          ) || '-'}
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="dettaglio_dei_dipendenti_e_consulenti"
          />
          <br />
          <b>{leadStep2?.dettaglio_dei_dipendenti_e_consulenti || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="struttura_della_proprieta_e_dei_soci_prevalenti"
          />
          <br />
          <b>
            {leadStep2?.struttura_della_proprieta_e_dei_soci_prevalenti || '-'}
          </b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="storia_di_lavoro_congiunta_dei_fondatori_e_capacita_di_gestire_conflitti_potenziali"
          />
          <br />
          <b>
            {leadStep2?.storia_di_lavoro_congiunta_dei_fondatori_e_capacita_di_gestire_conflitti_potenziali ||
              '-'}
          </b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage messages={messages} messageId="prodotto_servizio" />
          <br />
          <b>{leadStep2?.prodotto_servizio || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage messages={messages} messageId="business_model" />
          <b>{leadStep2?.business_model || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage messages={messages} messageId="utenti_clienti" />
          <br />
          <b>{leadStep2?.utenti_clienti || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="supply_chain_fornitori"
          />
          <br />
          <b>{leadStep2?.supply_chain_fornitori || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="partnership_validanti"
          />
          <br />
          <b>{leadStep2?.partnership_validanti || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage messages={messages} messageId="main_competitor" />
          <br />
          <b>{leadStep2?.main_competitor || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="descrizione_del_modello_di_business"
          />
          <br />
          <b>{leadStep2?.descrizione_del_modello_di_business || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage messages={messages} messageId="revenue_model" />
          <br />
          <b>{leadStep2?.revenue_model || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="mercato_di_riferimento"
          />
          <br />
          <b>{leadStep2?.mercato_di_riferimento || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="date_e_valutazione_pre_money_post_money"
          />
          <br />
          <b>{leadStep2?.date_e_valutazione_pre_money_post_money || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage messages={messages} messageId="business_metrics" />
          <br />
          <b>{leadStep2?.business_metrics || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="brevetti_e_stato_approvazione"
          />
          <br />
          <b>{leadStep2?.brevetti_e_stato_approvazione || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="situazione_debitoria_e_patrimoniale_aggiornata"
          />
          <br />
          <b>
            {leadStep2?.situazione_debitoria_e_patrimoniale_aggiornata || '-'}
          </b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="fatturato_prospettico_dell_anno_in_corso"
          />
          <br />
          <b>{leadStep2?.fatturato_prospettico_dell_anno_in_corso || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage messages={messages} messageId="fatturato_attuale" />
          <br />
          <b>{leadStep2?.fatturato_attuale || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage messages={messages} messageId="burn_rate_mensile" />
          <b>{leadStep2?.burn_rate_mensile || '-'}</b>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="disponibilita_di_cassa"
          />
          <b>{leadStep2?.disponibilita_di_cassa || '-'}</b>
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" color="textPrimary">
          -
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ApplyLeadStep2Preview;
