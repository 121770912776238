/*
 * IndustryText Messages
 *
 * This contains all the text for the IndustryText container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.IndustryText';

export default defineMessages({
  errorIndustry: {
    id: `${scope}.error_industry.message`,
    defaultMessage: 'Unable to retrieve industry',
  },
});
