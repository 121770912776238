/**
 *
 * ApplyLegalRepresentativePreview
 *
 */

import { Grid } from '@mui/material';
import { Typography } from '@mamacrowd/ui-kit';
import FormattedMessage from 'components/FormattedMessage';
import { UserProfile } from 'types/UserProfile';
import messages from './messages';

export interface ApplyLegalRepresentativePreviewProps {
  userProfile: UserProfile;
}

export function ApplyLegalRepresentativePreview({
  userProfile,
}: ApplyLegalRepresentativePreviewProps) {
  return userProfile ? (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="legalRepresentative"
            values={{
              name: `${userProfile.firstName} ${userProfile.lastName}`,
            }}
            isHtml
          />
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="fiscalCode"
            values={{
              fiscalCode: userProfile.fiscalCode,
            }}
            isHtml
          />
        </Typography>
      </Grid>
    </Grid>
  ) : null;
}

export default ApplyLegalRepresentativePreview;
