// REAL ESTATE CAMPAIGN CHIP
import { Chip } from '@mamacrowd/ui-kit';
import { FormattedMessage } from 'components/FormattedMessage';

import messages from './messages';
import { useStyle } from './RealEstateCampaignChip.style';

export interface RealEstateCampaignChipProps {
  isREIncome?: boolean;
}

export function RealEstateCampaignChip({
  isREIncome,
}: RealEstateCampaignChipProps) {
  const classes = useStyle();

  return (
    <Chip
      color="realEstate"
      variant={isREIncome ? 'filled' : 'outlined'}
      size="small"
      radius={5}
      label={
        <FormattedMessage
          messages={messages}
          messageId={
            isREIncome ? 'realEstateIncomeChip' : 'realEstateDevelopmentChip'
          }
        />
      }
      className={classes.chipLabelUppercase}
    />
  );
}

export default RealEstateCampaignChip;
