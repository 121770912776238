import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the industryText state domain
 */

const selectIndustryTextDomain = state => state.industryText || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by IndustryText
 */

const makeSelectIndustryText = () =>
  createSelector(selectIndustryTextDomain, substate => substate);
const makeSelectIndustryTextIndustry = () =>
  createSelector(selectIndustryTextDomain, substate => substate.industry);
const makeSelectIndustryTextLoading = () =>
  createSelector(selectIndustryTextDomain, substate => substate.loading);
const makeSelectIndustryTextError = () =>
  createSelector(selectIndustryTextDomain, substate => substate.error);

export default makeSelectIndustryText;
export {
  selectIndustryTextDomain,
  makeSelectIndustryTextIndustry,
  makeSelectIndustryTextLoading,
  makeSelectIndustryTextError,
};
