/*
 * ApplyLegalRepresentativePreview Messages
 *
 * This contains all the text for the ApplyLegalRepresentativePreview component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ApplyLegalRepresentativePreview';

export default defineMessages({
  legalRepresentative: {
    id: `${scope}.legal_representative.message`,
    defaultMessage: 'Legale Rappresentante: <b>{name}</b>',
  },
  fiscalCode: {
    id: `${scope}.fiscal_code.message`,
    defaultMessage: 'Codice Fiscale: <b>{fiscalCode}</b>',
  },
});
