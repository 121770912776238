/*
 *
 * CampaignApplicationViewPage actions
 *
 */

import { CampaignApplicationGetByIdRes } from 'types/Application';
import {
  LOAD_APPLICATION,
  LOAD_APPLICATION_SUCCESS,
  LOAD_APPLICATION_ERROR,
  CLEAN_APPLICATION,
} from './constants';
import { FEParsedError } from 'types/Error';
import { UserProfile } from 'types/UserProfile';

export function loadApplication(applicationId: string) {
  return {
    type: LOAD_APPLICATION,
    applicationId,
  };
}

export function loadApplicationSuccess(
  application: CampaignApplicationGetByIdRes,
  userProfile: UserProfile,
) {
  return {
    type: LOAD_APPLICATION_SUCCESS,
    application,
    userProfile,
  };
}

export function loadApplicationError(error: boolean | FEParsedError) {
  return {
    type: LOAD_APPLICATION_ERROR,
    error,
  };
}

export function cleanApplication() {
  return {
    type: CLEAN_APPLICATION,
  };
}
