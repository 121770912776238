import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 60,
    backgroundColor:
      process.env.SERVER_BE === 'staging'
        ? theme.palette.common.bgHeaderDev
        : theme.palette?.background?.default || theme.palette.common.white,
  },
  consoleLogoContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  consoleLogo: {
    width: 180,
    height: 'auto',
    maxHeight: 38,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: 150,
      margin: 'auto',
    },
  },
}));
