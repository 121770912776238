/*
 *
 * CampaignApplicationViewPage reducer
 *
 */
import produce from 'immer';
import {
  CLEAN_APPLICATION,
  LOAD_APPLICATION,
  LOAD_APPLICATION_ERROR,
  LOAD_APPLICATION_SUCCESS,
} from './constants';
import { CampaignApplicationGetByIdRes } from 'types/Application';
import { UserProfile } from 'types/UserProfile';

export type CampaignApplicationViewPageState = {
  loading: boolean;
  error: boolean;
  application: null | CampaignApplicationGetByIdRes;
  userProfile: null | UserProfile;
};

export const initialState: CampaignApplicationViewPageState = {
  loading: false,
  error: false,
  userProfile: null,
  application: null,
};

/* eslint-disable default-case, no-param-reassign */
const campaignApplicationViewPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_APPLICATION:
        draft.loading = true;
        draft.error = false;
        draft.application = null;
        draft.userProfile = null;
        break;
      case LOAD_APPLICATION_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.application = action.application;
        draft.userProfile = action.userProfile;
        break;
      case LOAD_APPLICATION_ERROR:
        draft.loading = false;
        draft.error = action.error || null;
        draft.application = null;
        draft.userProfile = null;
        break;
      case CLEAN_APPLICATION:
        draft.loading = false;
        draft.error = false;
        draft.application = null;
        draft.userProfile = null;
        break;
    }
  });

export default campaignApplicationViewPageReducer;
