import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type Props = object;

export const useStyle = makeStyles<Theme, Props>(() => ({
  chipLabelUppercase: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 14,
  },
}));
