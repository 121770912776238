/*
 * RealEstateCampaignChip Messages
 *
 * This contains all the text for the Card component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.RealEstateCampaignChip';

export default defineMessages({
  realEstateIncomeChip: {
    id: `${scope}.real_estate_income_chip.message`,
    defaultMessage: 'income',
  },
  realEstateDevelopmentChip: {
    id: `${scope}.real_estate_development_chip.message`,
    defaultMessage: 'development',
  },
});
