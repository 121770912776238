/**
 *
 * CampaignsPageFilters
 *
 */

import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectReducer } from 'redux-injectors';
import { useLocation } from 'react-router-dom';

import { Chip, DropdownMenu } from '@mamacrowd/ui-kit';
import { useTheme } from '@mui/material/styles';
import { Grid, useMediaQuery } from '@mui/material';

import FormattedMessage from 'components/FormattedMessage';
import { makeSelectCampaignsPageActive } from 'containers/CampaignsPage/selectors';

import { trackEvent } from 'utils/trackers';
import { Campaign, CampaignType, isTypeREDevelopment } from 'types/Campaign';

import {
  changeFilter,
  changeQuery,
  changeType,
  cleanQuery,
  cleanSearchFilters,
} from './actions';
import {
  makeChangeFilter,
  makeSelectQuery,
  makeSelectTypes,
} from './selectors';
import reducer from './reducer';
import messages from './messages';
import CampaignInput from './CampaignInput';
import { useStyle } from './CampaignsPageFilters.style';

export interface CampaignsPageFiltersProps {
  campaignsActive: Campaign[];
  filter: string;
  query: string;
  campaignTypes: CampaignType[];
  showNewsLabel: boolean;
  changeFilterProject: (filter: string) => void;
  changeQueryProject: (query: string) => void;
  changeTypeProject: (type: CampaignType[]) => void;
  cleanQueryProject: (newQuery: string) => void;
  cleanSearchFiltersData: () => void;
}

export function CampaignsPageFilters({
  filter,
  query,
  campaignTypes,
  showNewsLabel,
  changeFilterProject,
  changeQueryProject,
  changeTypeProject,
  cleanQueryProject,
}: CampaignsPageFiltersProps) {
  useInjectReducer({ key: 'campaignsPageFilters', reducer });

  const theme = useTheme();
  const classes = useStyle();
  const isOverMD = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();

  const isEquitySelected =
    campaignTypes && campaignTypes.includes(CampaignType.equity);
  const isRealEstateSelected =
    campaignTypes && campaignTypes.includes(CampaignType.realEstate);

  useEffect(() => {
    const queryType = new URLSearchParams(location.search).get('type');
    if (queryType === 'real-estate') {
      changeTypeProject([
        CampaignType.realEstate,
        CampaignType.realEstateIncome,
      ]);
    }
  }, [location]);

  const handleDropdownChange = (newFilter: string) => {
    if (newFilter !== filter) {
      changeFilterProject(newFilter);
      trackEvent('campaigns_order_change', newFilter, 'campaigns_list_filter');
    }
  };

  const addCampaignType = type => {
    if (!campaignTypes.includes(type)) {
      const newCampaignTypes = [...campaignTypes];
      newCampaignTypes.push(type);
      if (isTypeREDevelopment(type)) {
        newCampaignTypes.push(CampaignType.realEstateIncome);
      }
      changeTypeProject(newCampaignTypes);
      trackEvent(
        'campaigns_type_change',
        newCampaignTypes.join('_'),
        'campaigns_list_filter',
      );
    }
  };

  const removeCampaignType = type => {
    if (campaignTypes.includes(type)) {
      const newCampaignTypes = [...campaignTypes];
      newCampaignTypes.splice(newCampaignTypes.indexOf(type), 1);
      if (isTypeREDevelopment(type)) {
        newCampaignTypes.splice(
          newCampaignTypes.indexOf(CampaignType.realEstateIncome),
          1,
        );
      }
      changeTypeProject(newCampaignTypes);
      trackEvent(
        'campaigns_type_change',
        newCampaignTypes.join('_'),
        'campaigns_list_filter',
      );
    }
  };

  const search = (newQuery: string) => {
    if (query !== newQuery) {
      changeQueryProject(newQuery);
      trackEvent('campaigns_search', newQuery, 'campaigns_list_filter');
    }
  };

  const cleanQueryCallback = (newQuery: string) => {
    if (query !== newQuery) {
      cleanQueryProject(newQuery);
      changeQueryProject(newQuery);
      trackEvent('campaigns_search', newQuery, 'campaigns_list_filter');
    }
  };

  const chipsJSX = (
    <Grid item xs={12} md={6}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent={isOverMD ? 'center' : 'flex-start'}
      >
        <Grid item>
          <Chip
            label={
              <FormattedMessage messages={messages} messageId="chipEquity" />
            }
            color={isEquitySelected ? 'primary' : 'secondary'}
            style={{ color: theme.palette.text.primary }}
            clickable={!isEquitySelected}
            onClick={() => {
              addCampaignType(CampaignType.equity);
            }}
            onDelete={
              isEquitySelected
                ? () => removeCampaignType(CampaignType.equity)
                : undefined
            }
          />
        </Grid>
        <Grid item>
          <Chip
            label={
              <>
                <FormattedMessage
                  messages={messages}
                  messageId="chipRealEstate"
                />{' '}
                {showNewsLabel && (
                  <span className={classes.news}>
                    <FormattedMessage messages={messages} messageId="news" />
                  </span>
                )}
              </>
            }
            color={isRealEstateSelected ? 'primary' : 'secondary'}
            style={{ color: theme.palette.text.primary }}
            clickable={!isRealEstateSelected}
            onClick={() => {
              addCampaignType(CampaignType.realEstate);
            }}
            onDelete={
              isRealEstateSelected
                ? () => removeCampaignType(CampaignType.realEstate) // eslint-disable-line prettier/prettier
                : undefined
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={3} alignItems="end">
      <Grid item xs={12} md={8}>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12} md={6}>
            <CampaignInput
              query={query}
              onSearchPress={search}
              onCleanPress={cleanQueryCallback}
            />
          </Grid>
          {isOverMD ? chipsJSX : null}
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <DropdownMenu
          options={[
            {
              value: 'investment',
              message: (
                <FormattedMessage
                  messages={messages}
                  messageId="investmentFilter"
                />
              ),
            },
            {
              value: 'newest',
              message: (
                <FormattedMessage
                  messages={messages}
                  messageId="newestFilter"
                />
              ),
            },
            {
              value: 'value_raised',
              message: (
                <FormattedMessage
                  messages={messages}
                  messageId="amountRaised"
                />
              ),
            },
          ]}
          onChange={handleDropdownChange}
          label={
            <FormattedMessage messages={messages} messageId="dropdownTitle" />
          }
          value={filter}
          labelId="filter"
        />
      </Grid>
      {!isOverMD ? chipsJSX : null}
    </Grid>
  );
}

const mapStateToProps = createStructuredSelector({
  filter: makeChangeFilter(),
  query: makeSelectQuery(),
  campaignTypes: makeSelectTypes(),
  campaignsActive: makeSelectCampaignsPageActive(),
});

export function mapDispatchToProps(dispatch) {
  return {
    changeFilterProject: (filter: string) => {
      dispatch(changeFilter(filter));
    },
    changeQueryProject: (query: string) => {
      dispatch(changeQuery(query));
    },
    changeTypeProject: (types: CampaignType[]) => {
      dispatch(changeType(types));
    },
    cleanQueryProject: (newQuery: string) => {
      dispatch(cleanQuery(newQuery));
    },
    cleanSearchFiltersData: () => {
      dispatch(cleanSearchFilters());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CampaignsPageFilters);
