/**
 *
 * Error
 *
 */

import { Helmet } from 'react-helmet';
import GenericErrorImg from 'images/generic-error-img.svg';
import NoInternetErrorImg from 'images/no_internet.svg';
import { DEFAULT_LOCALE } from 'i18n';
import { palette } from 'theme/palette';

import { HOME_PAGE_ROUTE } from 'utils/constants';
import { trackException } from 'utils/trackers';

// import { useStyle } from './ErrorPage.style';

const EN_DICT = {
  SEOTitle: 'Oops',
  SEODescription: 'Something went wrong',
  ErrorCode: 'generic error',
  ErrorSubtitle: 'Oops!',
  ErrorTitle: 'Generic error',
  ErrorBody: 'We are sorry, but the page you are looking for is not available.',
  ErrorLink: 'Back to Home',
  ErrorNoInternetTitle: 'No Internet',
  ErrorNoInternetBody:
    'Sorry, but the page you are looking for is not available without a connection.',
};

const IT_DICT = {
  SEOTitle: 'Oops',
  SEODescription: 'Qualcosa è andato storto',
  ErrorCode: 'errore generico',
  ErrorSubtitle: 'Ops!',
  ErrorTitle: 'Errore generico',
  ErrorBody:
    'Siamo spiacenti, ma la pagina che si sta cercando non è disponibile.',
  ErrorLink: 'Torna alla Home',
  ErrorNoInternetTitle: 'No Internet',
  ErrorNoInternetBody:
    'Siamo spiacenti, ma la pagina che si sta cercando non è disponibile senza connessione.',
};

const DICT = {
  it: IT_DICT,
  en: EN_DICT,
};

export function ErrorPage() {
  // const classes = useStyle();
  const str = `${window.location.href}`;
  const matches = str.match(/\/(it|en)\//i);

  let lang = 'it';

  if (matches && matches.length) {
    lang = matches[1]; // ordem1
  } else {
    lang = DEFAULT_LOCALE || 'it';
  }

  const dictionary = DICT[lang];

  trackException(`ErrorBoundary - ${window.location.href}`, true);

  return (
    <>
      <Helmet>
        <title>{dictionary.SEOTitle}</title>
        <meta name="description" content={dictionary.SEODescription} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '24px 40px 36px',
            maxWidth: '1440px',
            textAlign: 'left',
          }}
        >
          <div
            style={{
              boxSizing: 'border-box',
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                flexBasis: '100%',
                maxWidth: '100%',
                flexGrow: 0,
              }}
            >
              <div
                style={{
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  flexDirection: 'row',
                  marginTop: '-80px',
                  width: 'calc(100% + 80px)',
                  marginLeft: '-80px',
                  WebkitBoxPack: 'justify',
                  justifyContent: 'space-between',
                  WebkitBoxAlign: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    paddingLeft: '80px',
                    paddingTop: '80px',
                    flexGrow: 0,
                  }}
                  className="INTERNALerrorGridItem"
                >
                  <h4
                    style={{
                      marginBottom: '20px',
                      textTransform: 'uppercase',
                      fontSize: '14px',
                      fontFamily: 'Montserrat',
                      lineHeight: '1.235',
                      color: palette.text.secondary,
                      display: 'block',
                      fontWeight: 'bold',
                      unicodeBidi: 'isolate',
                      fontWeight: 400,
                    }}
                  >
                    {dictionary.ErrorCode}
                  </h4>
                  <h3
                    style={{
                      fontWeight: 400,
                      lineHeight: 1,
                      margin: 0,
                      fontSize: '26px',
                      fontFamily: 'Montserrat',
                      boxSizing: 'border-box',
                      backgroundRepeat: 'no-repeat',
                      display: 'block',
                      marginInlineStart: '0px',
                      marginInlineEnd: '0px',
                      unicodeBidi: 'isolate',
                    }}
                  >
                    {dictionary.ErrorSubtitle}
                  </h3>
                  <h1
                    style={{
                      lineHeight: 1.2,
                      marginTop: 0,
                      marginBottom: 50,
                      fontSize: '40px',
                      fontFamily: 'Montserrat',
                      fontWeight: 700,
                    }}
                  >
                    {!navigator.onLine
                      ? dictionary.ErrorNoInternetTitle
                      : dictionary.ErrorTitle}
                  </h1>
                  <p
                    style={{
                      marginBottom: '30px',
                      marginTop: 0,
                      fontFamily: 'MontSerrat',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: 1.3,
                    }}
                  >
                    {!navigator.onLine
                      ? dictionary.ErrorNoInternetBody
                      : dictionary.ErrorBody}
                  </p>
                  <a href={HOME_PAGE_ROUTE} target="_self">
                    {dictionary.ErrorLink}
                  </a>
                </div>
                <div
                  style={{
                    paddingLeft: '80px',
                    paddingTop: '80px',
                    flexGrow: 0,
                  }}
                  className="INTERNALerrorGridItem"
                >
                  {!navigator.onLine ? (
                    <img
                      src={NoInternetErrorImg}
                      alt="generic error"
                      style={{
                        width: '100%',
                        margin: '0 auto',
                      }}
                    />
                  ) : (
                    <img
                      src={GenericErrorImg}
                      alt="generic error"
                      style={{
                        width: '100%',
                        margin: '0 auto',
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Section>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={10}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="h4"
              color="textSecondary"
              className={classes.errorCode}>
              {dictionary.ErrorCode}
            </Typography>
            <Typography variant="h3" className={classes.errorSubtitle}>
              {dictionary.ErrorSubtitle}
            </Typography>
            <Typography variant="h1" className={classes.errorTitle}>
              {!navigator.onLine
                ? dictionary.ErrorNoInternetTitle
                : dictionary.ErrorTitle}
            </Typography>
            <Typography className={classes.errorBody}>
              {!navigator.onLine
                ? dictionary.ErrorNoInternetBody
                : dictionary.ErrorBody}
            </Typography>
            <a href={HOME_PAGE_ROUTE} target="_self" forceExternal>
              {dictionary.ErrorLink}
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            {!navigator.onLine ? (
              <img
                src={NoInternetErrorImg}
                alt="generic error"
                className={classes.errorImg}
              />
            ) : (
              <img
                src={GenericErrorImg}
                alt="generic error"
                className={classes.errorImg}
              />
            )}
          </Grid>
        </Grid>
      </Section> */}
    </>
  );
}

ErrorPage.propTypes = {};

export default ErrorPage;
