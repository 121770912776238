import {
  IEntityProfile,
  IEntityProfileBusinessPartners,
  IEntityProfileConnection,
  IRichdataBalancesheet,
  IRichdataBeneficialOwner,
  IRichdataEntityRisksNegativeEventsFlag,
  IRichdataRisksNegativeEvents,
} from './RichdataDetails';
import { paths } from './webapi';

export type ApplyCapitalIncreases = {
  requestedCapitalIncreaseRaise: number;
  requestedCapitalIncreasePremoney: number;
  requestedCapitalIncreaseDate: number;
  latestCapitalIncreaseRaise?: number;
  latestCapitalIncreasePremoney?: number;
  latestCapitalIncreaseDate?: string;
  capitalIncrease?: boolean;
};

export type ApplyCompanyDetails = {
  incubatorId?: string[] | null;
  industry: number;
  isInnovative: boolean;
  nameInstitutionalInvestor: string;
  numFounders: number;
  patentStatus: 'no' | 'deposited' | 'pct' | 'obtained';
  webUrl: string;
};

export type ApplyCompanyProfile = {
  VATNumber: string;
  fiscalCode: string;
  name: string;
  subjectId: number;
};

export type ApplyDocuments = {
  pitchKey: string | null;
  signedBusinessPlanUrl: string | null;
  signedPitchUrl: string | null;
};

export type ApplyFinancialDataCompany = {
  bestCustomerRevenuePerc: number;
  financialGrantRange:
    | '0'
    | '< 10k'
    | '10k - 50k'
    | '50k - 100k'
    | '100k - 200k'
    | '> 200k';
  financialMCCRange:
    | '0'
    | '< 10k'
    | '10k - 50k'
    | '50k - 100k'
    | '100k - 200k'
    | '> 200k';
  last1Year: {
    EBITDA: number;
    amountBankDebt: number;
    productionValue: number;
  };
  revenueMonthly: number;
};

export type ApplyForecastData = {
  forecastEBITDA: number;
  forecastEBITDA3Years: number;
  forecastRevenues: number;
  forecastRevenues3Years: number;
};

export enum CompanyMaturity {
  'business_idea' = 'business_idea',
  'pre_market' = 'pre_market',
  'market' = 'market',
  'growth' = 'growth',
}

export enum CrowdfundingGoal {
  'has_large_audience' = 'has_large_audience',
  'recommended' = 'recommended',
  'market_feedback' = 'market_feedback',
  'solve_customer_problems' = 'solve_customer_problems',
  'ecf_referral' = 'ecf_referral',
  'other' = 'other',
}

export enum CrowdfundingKnowledge {
  'excellent' = 'excellent',
  'good' = 'good',
  'dont_know' = 'dont_know',
}

export enum GoalRangeMax {
  '_100000' = 100000,
  '_250000' = 250000,
  '_500000' = 500000,
  '_1000000' = 1000000,
  '_2000000' = 2000000,
  '_3000000' = 3000000,
  '_9999999' = 9999999,
}

export enum RaiseDateMaxMonths {
  '_3' = 3,
  '_6' = 6,
  '_12' = 12,
  '_99' = 99,
}
export enum RaisePeriodMaxMonths {
  '_12' = 12,
  '_24' = 24,
  '_36' = 36,
  '_99' = 99,
}
export enum ApplyLeadLocation {
  'milan' = 'milan',
  'rome' = 'rome',
  'florence' = 'florence',
  'turin' = 'turin',
  'venice' = 'venice',
  'bologna' = 'bologna',
  'other' = 'other',
}

export enum ApplyLeadType {
  residential = 'residential',
  office = 'office',
  hotellerie = 'hotellerie',
  retail = 'retail',
  industrial = 'industrial',
  other = 'other',
}

export enum ApplyCampaignType {
  equity_company = 'equity_company',
  real_estate = 'real_estate',
}

export enum ApplyLeadProduct {
  not_yet_developed = 'not_yet_developed',
  prototype_only = 'prototype_only',
  developed_not_commercialized = 'developed_not_commercialized',
  one_client = 'one_client',
  more_client = 'more_client',
}

export enum ApplyLeadFounderSkills {
  tech_only = 'tech_only',
  business_only = 'business_only',
  full_team = 'full_team',
}

export enum ApplyLeadCapitalRaisedSource {
  founders = 'founders',
  friends_and_family = 'friends_and_family',
  equity_crowdfunding = 'equity_crowdfunding',
  business_angels = 'business_angels',
  venture_capital = 'venture_capital',
  public_announcement_or_grant = 'public_announcement_or_grant',
  other = 'other',
}

export interface ApplyLead {
  webUrl?: string;
  /** @enum {unknown} */
  companyMaturity?: 'business_idea' | 'pre_market' | 'market' | 'growth';
  /** Format: float */
  raiseDateMaxMonths?: number;
  /** Format: float */
  raisePeriodMaxMonths?: number;
  location?: string;
  type?: string;
  /** Format: float */
  goalRangeMax?: number;
  /** @enum {unknown} */
  crowdfundingGoal?:
    | 'has_large_audience'
    | 'recommended'
    | 'market_feedback'
    | 'solve_customer_problems'
    | 'ecf_referral'
    | 'other';
  /** @enum {unknown} */
  crowdfundingKnowledge?: 'excellent' | 'good' | 'dont_know';
  founder?: {
    /** @enum {unknown} */
    skills?: 'to_be_defined' | 'tech_only' | 'business_only' | 'full_team';
    linkedinProfileURL?: string;
  };
  businessDescription?: {
    companyDescription?: string;
    industry?: number;
    /** @enum {unknown} */
    product?:
      | 'not_yet_developed'
      | 'prototype_only'
      | 'developed_not_commercialized'
      | 'one_client'
      | 'more_client';
  };
  equityHistory?: {
    existingInvestors?: string;
    /** @enum {unknown} */
    capitalRaisedSource?:
      | 'founders'
      | 'friends_and_family'
      | 'equity_crowdfunding'
      | 'business_angels'
      | 'venture_capital'
      | 'public_announcement_or_grant'
      | 'other';
    capitalRaisedAmount?: string;
  };
}

export type MarketingExpenseRange =
  | '1k - 5k'
  | '5k - 10k'
  | '10k - 25k'
  | '> 25k';

export interface ApplyMarketing {
  marketingExpenseRange: MarketingExpenseRange;
  facebookFollowers: number;
  instagramFollowers: number;
  twitterFollowers: number;
  youtubeFollowers: number;
}

export enum ApplyStatus {
  lead_draft = 'lead_draft',
  lead_step_1 = 'lead_step_1',
  lead_complete = 'lead_complete',
  opportunity = 'opportunity',
  application_draft = 'application_draft',
  application_complete = 'application_complete',
  application_accepted = 'application_accepted',
  application_refused = 'application_refused',
}

interface ApplyPrivacy {
  privacyPolicy: boolean;
  referralCode: string | null;
}

export interface ApplyLeadStep2 {
  brevetti_e_stato_approvazione?: string;
  burn_rate_mensile: string;
  business_metrics?: string;
  business_model: string;
  coinvolgimento_e_dedizione_a_tempo_pieno_dei_fondatori_nel_progetto: string;
  date_e_valutazione_pre_money_post_money?: string;
  descrizione_del_modello_di_business: string;
  descrizione_del_team_founder_completa: string;
  dettaglio_dei_dipendenti_e_consulenti: string;
  disponibilita_di_cassa: string;
  founders: string;
  linkedin_profili_dei_founder_e_key_people_del_team:
    | string
    | {
        linkedinURL?: string | undefined;
        age?: string | undefined;
        name: string;
        surname: string;
      }[];
  main_competitor: string;
  mercato_di_riferimento?: string;
  partnership_validanti: string;
  prodotto_servizio: string;
  revenue_model: string;
  storia_di_lavoro_congiunta_dei_fondatori_e_capacita_di_gestire_conflitti_potenziali: string;
  storia_ed_esperienza_dei_fondatori: string;
  struttura_della_proprieta_e_dei_soci_prevalenti: string;
  supply_chain_fornitori: string;
  utenti_clienti: string;
  situazione_debitoria_e_patrimoniale_aggiornata?: string;
  fatturato_prospettico_dell_anno_in_corso?: string;
  fatturato_attuale?: string;
}

export interface ApplyLeadAnswer {
  companyProfile: ApplyCompanyProfile;
  lead: ApplyLead;
  leadStep2?: ApplyLeadStep2;
  privacy: ApplyPrivacy;
}

export interface ApplyAnswer extends ApplyLeadAnswer {
  companyDetails: ApplyCompanyDetails;
  fundraiserAndPrevCapitalIncreases: ApplyCapitalIncreases;
  financialDataCompany: ApplyFinancialDataCompany;
  forecastData: ApplyForecastData;
  marketing: ApplyMarketing;
  documents: ApplyDocuments;
}

interface ApplyRichdata {
  liveData: IEntityProfile;
  connections: IEntityProfileConnection;
  balancesheets: {
    last1Year: IRichdataBalancesheet | null;
    last2Years: IRichdataBalancesheet | null;
  };
  beneficialOwner: IRichdataBeneficialOwner;
  businessPartners: IEntityProfileBusinessPartners;
  risksNegativeEvents: IRichdataRisksNegativeEvents;
  risksNegativeEventsFlags: IRichdataEntityRisksNegativeEventsFlag;
}

export interface BaseApplication {
  id: string;
  createdAt: string;
  version: string;
  vatNumber: string;
  source: string | null;
  status: `${ApplyStatus}`;
  type: `${ApplyCampaignType}`;
  richdata?: ApplyRichdata;
}

export type Application = BaseApplication &
  (
    | {
        applicationDate: string;
        leadDate: null | string;
        leadStep2Date: null | string;
        answers: ApplyAnswer;
      }
    | {
        applicationDate: null;
        leadDate: string | null;
        leadStep2Date: string | null;
        answers: ApplyLeadAnswer;
      }
  );

export type CampaignApplicationGetAllRes =
  paths['/me/campaign-application']['get']['responses']['200']['content']['application/json'];
export type CampaignApplicationGetByIdRes =
  paths['/me/campaign-application/{id}']['get']['responses']['200']['content']['application/json'];
export type CampaignApplicationLeadPostReqBody =
  paths['/me/campaign-application/lead']['post']['requestBody']['content']['multipart/form-data'];
export type CampaignApplicationLeadPutReqBody =
  paths['/me/campaign-application/{id}/lead']['put']['requestBody']['content']['multipart/form-data'];
export type CampaignApplicationLeadStep2ReqBody =
  paths['/me/campaign-application/{id}/lead/step-2']['put']['requestBody']['content']['application/json'];
export type CampaignApplicationLeadStep1ReqBody =
  paths['/me/campaign-application/{id}/lead/step-1']['patch']['requestBody']['content']['application/json'];
export type CampaignApplicationLeadCreateRes =
  paths['/me/campaign-application/lead']['post']['responses']['201']['content']['application/json'];

export enum CampaignApplicationVersions {
  v1_0 = '1.0.20201110',
  v2_1 = '2.131.20241109',
  v2_0 = '2.48.20210610',
  v2_0_old = '1.0.20210610',
}

export const isApplicationFromGetById = (
  obj: any,
): obj is CampaignApplicationGetByIdRes => !!obj?.id && !!obj?.status;
