import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  MAX_WIDTH_PAGE,
  PAGE_CONTAINER_PADDING,
  PAGE_CONTAINER_MOBILE_PADDING,
} from 'utils/constants';

type Props = {
  maxWidth: 'thin' | 'large';
  hasIcon?: boolean;
  coverMaxHeight?: number;
  hasTitle?: boolean;
};

const ICON_WIDTH = 150;

export const useStyle = makeStyles<Theme, Props>(theme => ({
  coverImage: propsCover => ({
    height: propsCover.coverMaxHeight ? 'none' : '50vh',
    maxHeight: propsCover.coverMaxHeight || 'none',
    position: 'relative',
    width: '100%',
    objectFit: 'cover',
  }),
  pageLayout: propsPageLayot => ({
    minHeight: '50vh',
    maxWidth: propsPageLayot.maxWidth === 'large' ? MAX_WIDTH_PAGE : 1024,
    width: '100%',
    margin: '0 auto',
    padding: PAGE_CONTAINER_PADDING,
    [theme.breakpoints.down('md')]: {
      padding: PAGE_CONTAINER_MOBILE_PADDING,
    },
  }),
  imageContainer: {
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      maxWidth: ICON_WIDTH,
      display: 'block',
    },
  },
  image: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 200,
    },
  },
  titleContainer: propsTitle => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: propsTitle.hasIcon ? `calc(100% - ${ICON_WIDTH}px)` : '100%',
    },
  }),
  stroke: {
    borderColor: theme.palette.secondary.main,
  },
  childrenContainer: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
}));
