/**
 *
 * IndustryText
 *
 */

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { Typography, TextProps } from '@mamacrowd/ui-kit';
import ErrorForm from 'components/ErrorForm';
import FormattedMessage from 'components/FormattedMessage';

import { FEParsedError } from 'types/Error';
import { Industry } from 'types/Company';

import {
  makeSelectIndustryTextError,
  makeSelectIndustryTextIndustry,
  makeSelectIndustryTextLoading,
} from './selectors';
import { getIndustry, clearIndustry } from './actions';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';

export interface IndustryTextProps {
  industryId?: number;
  getIndustryData: (isInWeb: boolean, id: number) => void;
  clearIndustryData: () => void;
  industry?: Industry;
  loading?: boolean;
  error: any;
  variant?: TextProps['variant'];
  color?: TextProps['color'];
  bold?: boolean;
  isInWeb?: boolean;
}

export function IndustryText({
  industryId,
  getIndustryData,
  clearIndustryData,
  industry,
  loading,
  error,
  isInWeb = true,
  variant = 'body1',
  color = 'textPrimary',
  bold = false,
}: IndustryTextProps) {
  useInjectReducer({ key: 'industryText', reducer });
  useInjectSaga({ key: 'industryText', saga });

  const [snackbarError, setSnackbarError] = useState<FEParsedError | null>(
    null,
  );

  useEffect(() => {
    if (industryId && getIndustryData) {
      getIndustryData(isInWeb, industryId);
    }
  }, [getIndustryData, industryId, isInWeb]);

  useEffect(() => {
    if (error) {
      setSnackbarError({
        message: (
          <FormattedMessage messages={messages} messageId="errorIndustry" />
        ),
        details: error?.details || null,
      });
    }
  }, [error]);

  useEffect(
    () => () => {
      clearIndustryData();
    },
    [],
  );

  const handleSnackbarClose = () => {
    setSnackbarError(null);
  };

  return (
    <>
      {!loading && (
        <Typography variant={variant} color={color} component="span">
          {bold ? <b>{industry?.title || '-'}</b> : industry?.title || '-'}
        </Typography>
      )}
      <ErrorForm
        toast
        errorMessage={snackbarError ? snackbarError.message : null}
        details={snackbarError ? snackbarError.details : null}
        handleSnackbarClose={handleSnackbarClose}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  industry: makeSelectIndustryTextIndustry(),
  loading: makeSelectIndustryTextLoading(),
  error: makeSelectIndustryTextError(),
});

function mapDispatchToProps(dispatch) {
  return {
    getIndustryData: (isInWeb, id) => {
      dispatch(getIndustry(isInWeb, id));
    },
    clearIndustryData: () => {
      dispatch(clearIndustry());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndustryText);
