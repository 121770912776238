/*
 * CampaignApplicationViewPage Messages
 *
 * This contains all the text for the CampaignApplicationViewPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CampaignApplicationViewPage';

export default defineMessages({
  yourCompanyApplication: {
    id: `${scope}.your_company_application.message`,
    defaultMessage: 'Your company application',
  },
  legalRepresentative: {
    id: `${scope}.legal_representative.message`,
    defaultMessage: 'Legal representative',
  },
  companyDetails: {
    id: `${scope}.company_details.message`,
    defaultMessage: 'Company profile',
  },
  projectDescription: {
    id: `${scope}.project_description.message`,
    defaultMessage: 'Project description',
  },
  step2: {
    id: `${scope}.step_2.message`,
    defaultMessage: 'Step 2',
  },
  applicationDetails: {
    id: `${scope}.application_details.message`,
    defaultMessage:
      'Di seguito trovi il riepilogo della tua candidatura.{linebreak}Il team di Mamacrowd ti invierà una risposta con l’esito della verifica.',
  },
});
