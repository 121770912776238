import { createSelector } from 'reselect';
import { CampaignApplicationViewPageState, initialState } from './reducer';

// Direct selector to the campaignApplicationViewPage state domain

const selectCampaignApplicationViewPageDomain = (state: {
  campaignApplicationViewPage: CampaignApplicationViewPageState;
}) => state.campaignApplicationViewPage || initialState;

// Default selector used by CampaignApplicationViewPage

const makeSelectCampaignApplicationViewPage = () =>
  createSelector(selectCampaignApplicationViewPageDomain, substate => substate);
const makeSelectCampaignApplicationViewPageLoading = () =>
  createSelector(
    selectCampaignApplicationViewPageDomain,
    substate => substate.loading,
  );
const makeSelectCampaignApplicationViewPageError = () =>
  createSelector(
    selectCampaignApplicationViewPageDomain,
    substate => substate.error,
  );
const makeSelectCampaignApplicationViewPageApplication = () =>
  createSelector(
    selectCampaignApplicationViewPageDomain,
    substate => substate.application,
  );
const makeSelectCampaignApplicationViewPageUserProfile = () =>
  createSelector(
    selectCampaignApplicationViewPageDomain,
    substate => substate.userProfile,
  );

export default makeSelectCampaignApplicationViewPage;
export {
  selectCampaignApplicationViewPageDomain,
  makeSelectCampaignApplicationViewPageLoading,
  makeSelectCampaignApplicationViewPageError,
  makeSelectCampaignApplicationViewPageApplication,
  makeSelectCampaignApplicationViewPageUserProfile,
};
