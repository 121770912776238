/*
 *
 * IndustryText actions
 *
 */

import {
  GET_INDUSTRY,
  GET_INDUSTRY_SUCCESS,
  GET_INDUSTRY_ERROR,
  CLEAR_INDUSTRY,
} from './constants';
import { Industry } from 'types/Company';

export function getIndustry(isInWeb: boolean, id: number) {
  return {
    type: GET_INDUSTRY,
    isInWeb,
    id,
  };
}

export function getIndustrySuccess(industry: Industry) {
  return {
    type: GET_INDUSTRY_SUCCESS,
    industry,
  };
}

export function getIndustryError(error: boolean | Error) {
  return {
    type: GET_INDUSTRY_ERROR,
    error,
  };
}

export function clearIndustry() {
  return {
    type: CLEAR_INDUSTRY,
  };
}
