/*
 *
 * IndustryText reducer
 *
 */
import produce from 'immer';
import {
  GET_INDUSTRY,
  GET_INDUSTRY_ERROR,
  GET_INDUSTRY_SUCCESS,
  CLEAR_INDUSTRY,
} from './constants';

export const initialState = {
  loading: false,
  industry: null,
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const industryTextReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_INDUSTRY:
        draft.loading = true;
        draft.industry = null;
        draft.error = false;
        break;
      case GET_INDUSTRY_SUCCESS:
        draft.loading = false;
        draft.industry = action.industry;
        draft.error = false;
        break;
      case GET_INDUSTRY_ERROR:
        draft.loading = false;
        draft.industry = null;
        draft.error = action.error || true;
        break;
      case CLEAR_INDUSTRY:
        draft.loading = false;
        draft.industry = null;
        draft.error = false;
        break;
    }
  });

export default industryTextReducer;
