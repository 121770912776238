import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  imgWithBorder: {
    width: 100,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
  },
  iconWithBorder: {
    fontSize: 100,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
  },
  warningContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
}));
