import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontSize: 16,
  fontFamily: 'Montserrat',
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: 40,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    lineHeight: 1.55,
    '@media (max-width:899px)': {
      fontSize: 30,
    },
  },
  h2: {
    fontSize: 30,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: 0.8,
    '@media (max-width:899px)': {
      fontSize: 26,
    },
  },
  h3: {
    fontSize: 26,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    '@media (max-width:899px)': {
      fontSize: 24,
    },
  },
  h4: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: 400,
    color: '#B9B9B9',
  },
  subtitle1: {
    margin: 0,
    fontSize: 11,
    width: '100%',
  },
  subtitle2: {
    margin: 0,
    fontSize: 11,
    width: '100%',
  },
  body1: {
    fontFamily: 'MontSerrat',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
    '@media (max-width:899px)': {
      fontSize: 14,
    },
  },
  body2: {
    margin: 0,
    fontSize: 14,
    color: '#808080',
    '@media (max-width:899px)': {
      fontSize: 12,
    },
  },
  caption: {
    margin: 0,
    fontSize: 12,
    width: '100%',
  },
};

export const consoleTypography: TypographyOptions = {
  fontSize: 13,
  fontFamily: 'Roboto',
  fontWeightLight: 50,
  fontWeightRegular: 100,
  fontWeightMedium: 300,
  fontWeightBold: 700,
  h1: {
    margin: 0,
    lineHeight: '120%',
    fontSize: 40,
    padding: '5px 0',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  h2: {
    margin: 0,
    padding: '5px 0',
    fontSize: 30,
    fontWeight: 'bold',
  },
  h3: {
    margin: 0,
    padding: '5px 0',
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    lineHeight: '1.2',
  },
  h4: {
    margin: 0,
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  body1: {
    margin: 0,
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.2,
  },
  body2: {
    fontSize: 11,
    margin: 0,
    padding: 0,
  },
};
