import Logger from 'loglevel';
import { takeLatest, call, put } from 'redux-saga/effects';
import { request } from 'utils/request';
import { manageSagaError } from 'utils/errorManager';
import makeApiUrl from 'utils/makeApiUrl';
import {
  loadApplication,
  loadApplicationSuccess,
  loadApplicationError,
} from './actions';
import { LOAD_APPLICATION } from './constants';
import {
  ME_CAMPAIGN_APPLICATION_URL,
  ME_USER_PROFILE_URL,
} from 'utils/constants';

export function* loadApplicationSaga({
  applicationId,
}: ReturnType<typeof loadApplication>) {
  try {
    const requestUrlApplication = makeApiUrl(
      ME_CAMPAIGN_APPLICATION_URL.replace(':applicationId', applicationId),
    );
    const application = yield call(request, requestUrlApplication);
    Logger.log(['SAGAS', 'LOAD SINGLE APPLICATION', 'SUCCESS', application]);

    const requestUrlUserProfile = makeApiUrl(ME_USER_PROFILE_URL);
    const userProfile = yield call(request, requestUrlUserProfile);
    Logger.log(['SAGAS', 'GET USER PROFILE', 'SUCCESS', userProfile]);

    yield put(loadApplicationSuccess(application, userProfile));
  } catch (err) {
    const error = yield manageSagaError('CampaignApplicationViewPage', err);
    yield put(loadApplicationError(error));
  }
}

export default function* campaignApplicationViewPageSaga() {
  yield takeLatest(LOAD_APPLICATION, loadApplicationSaga);
}
