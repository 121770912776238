/*
 * ApplyLeadPreview Messages
 *
 * This contains all the text for the ApplyLeadPreview component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ApplyLeadPreview';

export default defineMessages({
  webUrl: {
    id: `${scope}.web_url.message`,
    defaultMessage: 'Web URL: ',
  },
  type: {
    id: `${scope}.type.message`,
    defaultMessage: 'Type: ',
  },
  companyMaturity: {
    id: `${scope}.company_maturity.message`,
    defaultMessage: 'Company Maturity: ',
  },
  location: {
    id: `${scope}.location.message`,
    defaultMessage: 'Location: ',
  },
  raisePeriodMaxMonths: {
    id: `${scope}.raisePeriodMaxMonths.message`,
    defaultMessage: 'Raise period: ',
  },
  crowdfundingKnowledge: {
    id: `${scope}.crowdfunding_knowledge.message`,
    defaultMessage: 'Crowdfunding Knowledge: ',
  },
  raiseDateMaxMonths: {
    id: `${scope}.raise_date_max_months.message`,
    defaultMessage: 'Raise starting date: ',
  },
  goalRangeMax: {
    id: `${scope}.goal_range_max.message`,
    defaultMessage: 'Goal range: ',
  },
  crowdfundingGoal: {
    id: `${scope}.crowdfunding_goal.message`,
    defaultMessage: 'Crowdfunding Goal: ',
  },
  business_idea: {
    id: `${scope}.business_idea.message`,
    defaultMessage: "For now it's just an idea",
  },
  pre_market: {
    id: `${scope}.pre_market.message`,
    defaultMessage: 'Everything is ready to go, we are in the pre-launch phase',
  },
  market: {
    id: `${scope}.market.message`,
    defaultMessage: 'We have launched and are generating the first revenues',
  },
  growth: {
    id: `${scope}.growth.message`,
    defaultMessage:
      'We are definitely live and revenues are growing month over month',
  },
  has_large_audience: {
    id: `${scope}.has_large_audience.message`,
    defaultMessage:
      "I could involve many people in the company's growth plan (users, potential customers and current customers, small investors, my relational system and that of partners, etc.)",
  },
  recommended: {
    id: `${scope}.recommended.message`,
    defaultMessage: 'I was advised by my financial advisor',
  },
  market_feedback: {
    id: `${scope}.market_feedback.message`,
    defaultMessage:
      'I am interested in the feedback that the market can give to my entrepreneurial vision in this important moment of growth',
  },
  solve_customer_problems: {
    id: `${scope}.solve_customer_problems.message`,
    defaultMessage:
      'My business is able to generate interest among people because it solves the problems of many and helps to make the world a better place',
  },
  excellent: {
    id: `${scope}.excellent.message`,
    defaultMessage: 'I have an excellent knowledge of crowdfunding',
  },
  good: {
    id: `${scope}.good.message`,
    defaultMessage: 'I have a good knowledge of crowdfunding',
  },
  dont_know: {
    id: `${scope}.dont_know.message`,
    defaultMessage:
      "I don't know much, I'm interested in receiving more information",
  },
  3: {
    id: `${scope}.3.message`,
    defaultMessage: '< 3 months',
  },
  6: {
    id: `${scope}.6.message`,
    defaultMessage: '3-6 months',
  },
  12: {
    id: `${scope}.12.message`,
    defaultMessage: '6-12 months',
  },
  99: {
    id: `${scope}.99.message`,
    defaultMessage: '> 12 months',
  },
  100000: {
    id: `${scope}.100000.message`,
    defaultMessage: '100 K',
  },
  250000: {
    id: `${scope}.250000.message`,
    defaultMessage: '100K - 250K',
  },
  500000: {
    id: `${scope}.500000.message`,
    defaultMessage: '250K - 500K',
  },
  1000000: {
    id: `${scope}.1000000.message`,
    defaultMessage: '500K - 1Mln',
  },
  2000000: {
    id: `${scope}.2000000.message`,
    defaultMessage: '1Mln - 2Mln',
  },
  3000000: {
    id: `${scope}.3000000.message`,
    defaultMessage: '2Mln - 3Mln',
  },
  9999999: {
    id: `${scope}.9999999.message`,
    defaultMessage: '> 3Mln',
  },

  // REAL ESTATE
  realEstateType: {
    id: `${scope}.real_estate_type.message`,
    defaultMessage: 'Intended use',
  },
  residential: {
    id: `${scope}.residential.message`,
    defaultMessage: 'Residential',
  },
  office: {
    id: `${scope}.office.message`,
    defaultMessage: 'Office',
  },
  hotellerie: {
    id: `${scope}.hotellerie.message`,
    defaultMessage: 'Hotellerie',
  },
  retail: {
    id: `${scope}.retail.message`,
    defaultMessage: 'Retail',
  },
  industrial: {
    id: `${scope}.industrial.message`,
    defaultMessage: 'Industrial',
  },
  other: {
    id: `${scope}.other.message`,
    defaultMessage: 'Other',
  },

  realEstateRaisePeriodMaxMonths: {
    id: `${scope}.real_estate_raise_period_max_months.message`,
    defaultMessage: 'Project Duration',
  },
  re_12: {
    id: `${scope}.re_12.message`,
    defaultMessage: '< 12 months',
  },
  re_24: {
    id: `${scope}.re_24.message`,
    defaultMessage: '12 - 24 months',
  },
  re_36: {
    id: `${scope}.re_36.message`,
    defaultMessage: '24 - 36 months',
  },
  re_99: {
    id: `${scope}.re_99.message`,
    defaultMessage: '> 36 months',
  },

  realEstateLocation: {
    id: `${scope}.real_estate_location.message`,
    defaultMessage: 'City location',
  },
  milan: {
    id: `${scope}.milan.message`,
    defaultMessage: 'Milan',
  },
  rome: {
    id: `${scope}.rome.message`,
    defaultMessage: 'Rome',
  },
  florence: {
    id: `${scope}.florence.message`,
    defaultMessage: 'Florence',
  },
  turin: {
    id: `${scope}.turin.message`,
    defaultMessage: 'Turin',
  },
  venice: {
    id: `${scope}.venice.message`,
    defaultMessage: 'Venice',
  },
  bologna: {
    id: `${scope}.bologna.message`,
    defaultMessage: 'Bologna',
  },
  referrer: {
    id: `${scope}.referrer.message`,
    defaultMessage: 'Referrer: ',
  },
  financialAdvisor: {
    id: `${scope}.financial_advisor.message`,
    defaultMessage: 'Financial Advisor: ',
  },
  web: {
    id: `${scope}.web.message`,
    defaultMessage: 'Web',
  },
  linkedin: {
    id: `${scope}.linkedin.message`,
    defaultMessage: 'LinkedIn',
  },
  ecf_referral: {
    id: `${scope}.ecf_referral.message`,
    defaultMessage: 'Conosco qualcuno che ha fatto equity crowdfuning',
  },

  founderSkills: {
    id: `${scope}.founder_skills.message`,
    defaultMessage: 'Competenze founder: ',
  },
  linkedinProfileURL: {
    id: `${scope}.linkedin_profile_url.message`,
    defaultMessage: 'Linkedin Profile URL',
  },
  companyDescription: {
    id: `${scope}.company_description.message`,
    defaultMessage: 'Descrizione Società: ',
  },
  industry: {
    id: `${scope}.industry.message`,
    defaultMessage: 'Settore: ',
  },
  product: {
    id: `${scope}.product.message`,
    defaultMessage: 'Prodotto: ',
  },
  existingInvestors: {
    id: `${scope}.existing_investors.message`,
    defaultMessage: 'Investitori Presenti: ',
  },
  capitalRaisedSource: {
    id: `${scope}.capital_raised_source.message`,
    defaultMessage: 'Fonti di aumento capitale: ',
  },
  capitalRaisedAmount: {
    id: `${scope}.capital_raised_amount.message`,
    defaultMessage: 'Capitale raccolto in precedenza: ',
  },

  to_be_defined: {
    id: `${scope}.to_be_defined.message`,
    defaultMessage: 'Da definire',
  },
  tech_only: {
    id: `${scope}.tech_only.message`,
    defaultMessage: 'Competenze in ambito Tech',
  },
  business_only: {
    id: `${scope}.business_only.message`,
    defaultMessage: 'Competenze in ambito Business',
  },
  full_team: {
    id: `${scope}.full_team.message`,
    defaultMessage: 'Entrambe',
  },
  not_yet_developed: {
    id: `${scope}.not_yet_developed.message`,
    defaultMessage: 'Non ancora sviluppato',
  },
  prototype_only: {
    id: `${scope}.prototype_only.message`,
    defaultMessage: 'Solo prototipo',
  },
  developed_not_commercialized: {
    id: `${scope}.developed_not_commercialized.message`,
    defaultMessage: 'Sviluppato ma non ancora commercializzato',
  },
  one_client: {
    id: `${scope}.one_client.message`,
    defaultMessage: 'Un solo cliente',
  },
  more_client: {
    id: `${scope}.more_client.message`,
    defaultMessage: 'Più clienti',
  },
  founders: {
    id: `${scope}.founders.message`,
    defaultMessage: 'Founders',
  },
  friends_and_family: {
    id: `${scope}.friends_and_family.message`,
    defaultMessage: 'Amici e/o parenti',
  },
  equity_crowdfunding: {
    id: `${scope}.equity_crowdfunding.message`,
    defaultMessage: 'Equity Crowdfunding',
  },
  business_angels: {
    id: `${scope}.business_angels.message`,
    defaultMessage: 'Business Angels',
  },
  venture_capital: {
    id: `${scope}.venture_capital.message`,
    defaultMessage: 'Venture Capital',
  },
  public_announcement_or_grant: {
    id: `${scope}.public_announcement_or_grant.message`,
    defaultMessage: 'Bando pubblico/Grant',
  },
});
