import { takeLatest, call, put } from 'redux-saga/effects';
import Logger from 'loglevel';
import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import { SEC_INDUSTRY_URL, INDUSTRY_URL } from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';
import { GET_INDUSTRY } from './constants';
import { getIndustrySuccess, getIndustryError } from './actions';

type GetIndustryTextAction = {
  type: string;
  isInWeb: boolean;
  id: number;
};

export function* makeGetIndustry({ isInWeb, id }: GetIndustryTextAction) {
  let requestUrl = makeApiUrl(SEC_INDUSTRY_URL.replace(':industryId', `${id}`));
  if (isInWeb) {
    requestUrl = makeApiUrl(`${INDUSTRY_URL}/${id}`);
  }

  try {
    const industry = yield call(request, requestUrl);
    Logger.log(['SAGAS', 'INDUSTRY TEXT GET', industry]);
    yield put(getIndustrySuccess(industry));
  } catch (err) {
    const error = yield manageSagaError('IndustryText', err);
    yield put(getIndustryError(error));
  }
}

export default function* industryTextDropdownSaga() {
  yield takeLatest(GET_INDUSTRY, makeGetIndustry);
}
