import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles(theme => ({
  headerContainer: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
    backgroundColor:
      theme.palette?.background?.default || theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
  },
  pageContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));
