/* eslint-disable prettier/prettier */
/*
 *
 * CampaignApplicationViewPage constants
 *
 */

export const LOAD_APPLICATION = 'app/CampaignApplicationViewPage/LOAD_APPLICATION';
export const LOAD_APPLICATION_SUCCESS =
  'app/CampaignApplicationViewPage/LOAD_APPLICATION_SUCCESS';
export const LOAD_APPLICATION_ERROR =
  'app/CampaignApplicationViewPage/LOAD_APPLICATION_ERROR';
export const CLEAN_APPLICATION = 'app/CampaignApplicationViewPage/CLEAN_APPLICATION';
