/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as Sentry from '@sentry/react';
import { useInjectSaga } from 'redux-injectors';
import { injectIntl } from 'react-intl';

import LocalizedSwitch from 'containers/LocalizedSwitch';
import { ConnectedRouter } from 'connected-react-router';

import Box from 'components/Box';
import ScrollRestoration from 'components/ScrollRestoration';
import InternetExplorerDialog from 'components/InternetExplorerDialog';
import UtmProvider from 'containers/UtmProvider';
import LocalizedRouter from 'containers/LocalizedRouter';

/* START LAYOUT */
import WebLayout from 'layout/WebLayout';
import AuthLayout from 'layout/AuthLayout';
import ConsoleLayout from 'layout/ConsoleLayout';
import MeLayout from 'layout/MeLayout';
import LiteLayout from 'layout/LiteLayout';
import LandingLayout from 'layout/LandingLayout';
/* END LAYOUT */

/* START ROUTE */
import MeRoute from 'routes/MeRoute';
import PublicRoute from 'routes/PublicRoute';
import ProtectedRoute from 'routes/ProtectedRoute';
/* END ROUTE */

/* START MAMACROWD */
import HomePage from 'containers/HomePage/Loadable';
import RaiseCapitalPage from 'containers/RaiseCapitalPage/Loadable';
import CampaignApplicationViewPage from 'containers/CampaignApplicationViewPage';
import PortfolioPage from 'containers/PortfolioPage/Loadable';
import PortfolioDetailsPage from 'containers/PortfolioDetailsPage/Loadable';
import AccountPage from 'containers/AccountPage/Loadable';
import MyCampaignsPage from 'containers/MyCampaignsPage/Loadable';
import MyCompanyMonitoringPage from 'containers/MyCompanyMonitoringPage/Loadable';
import MyCompanyMonitoringFormPage from 'containers/MyCompanyMonitoringFormPage/Loadable';
import ChangePasswordForm from 'containers/ChangePasswordForm/Loadable';
import InvestorsTablePage from 'containers/InvestorsTablePage/Loadable';
import AnchorInvestorsTablePage from 'containers/AnchorInvestorsTablePage/Loadable';
import UploadDocumentsPage from 'containers/UploadDocumentsPage/Loadable';
import WatchlistPage from 'containers/WatchlistPage/Loadable';
import CampaignsPage from 'containers/CampaignsPage';
import CampaignPage from 'containers/CampaignPage/Loadable';
import EquityCalculatorPage from 'containers/EquityCalculatorPage/Loadable';
import InvestmentPage from 'containers/InvestmentPage/Loadable';
import PispPage from 'containers/PispPage/Loadable';
import PispSuccessPage from 'containers/PispSuccessPage/Loadable';
import InvestmentSuccessPage from 'containers/InvestmentSuccessPage/Loadable';
import NotFoundPage from 'components/NotFoundPage/Loadable';
import AccessDeniedPage from 'components/AccessDeniedPage/Loadable';
import ErrorPage from 'containers/ErrorPage/Loadable';
import LoginPage from 'containers/AuthorizationPages/LoginPage/Loadable';
import TokenLoginPage from 'containers/AuthorizationPages/TokenLoginPage/Loadable';
import RegisterPage from 'containers/AuthorizationPages/RegisterPage/Loadable';
import ForgotPage from 'containers/AuthorizationPages/ForgotPage/Loadable';
import ActivationMailPage from 'containers/AuthorizationPages/ActivationMailPage/Loadable';
import LinkedinAuthorizationLoginPage from 'containers/AuthorizationPages/Social/LinkedinAuthorizationLoginPage/Loadable';
import GoogleAuthorizationLoginPage from 'containers/AuthorizationPages/Social/GoogleAuthorizationLoginPage/Loadable';
import FacebookAuthorizationLoginPage from 'containers/AuthorizationPages/Social/FacebookAuthorizationLoginPage/Loadable';
import SocialAuthorizationLoginPage from 'containers/AuthorizationPages/Social/SocialAuthorizationLoginPage/Loadable';
import LinkedinAuthorizationRegisterPage from 'containers/AuthorizationPages/Social/LinkedinAuthorizationRegisterPage/Loadable';
import LinkedinTeamMemberRegisterPage from 'containers/AuthorizationPages/Social/LinkedinTeamMemberRegisterPage/Loadable';
import GoogleAuthorizationRegisterPage from 'containers/AuthorizationPages/Social/GoogleAuthorizationRegisterPage/Loadable';
import FacebookAuthorizationRegisterPage from 'containers/AuthorizationPages/Social/FacebookAuthorizationRegisterPage/Loadable';
import SocialAuthorizationRegisterPage from 'containers/AuthorizationPages/Social/SocialAuthorizationRegisterPage/Loadable';
import ResetPasswordPage from 'containers/AuthorizationPages/ResetPasswordPage/Loadable';
import SpidSuccessPage from 'containers/SpidSuccessPage/Loadable';
import Page from 'containers/Page/Loadable';
import Faq from 'containers/Faq/Loadable';
import NewsPage from 'containers/NewsPage/Loadable';
import BlogArticlePage from 'containers/BlogArticlePage/Loadable';
import PressPage from 'containers/PressPage/Loadable';
import SuccessStoriesPage from 'containers/SuccessStoriesPage/Loadable';
import EquityCalculatorPreviewPage from 'containers/EquityCalculatorPreviewPage/Loadable';
import UserUploadForm from 'containers/UserUploadForm/Loadable';
// import CampaignWizardComingSoon from 'containers/CampaignWizardComingSoon/Loadable';
import CampaignTaxBonus from 'containers/CampaignTaxBonus/Loadable';
import CampaignWizardPage from 'containers/CampaignWizardPage/Loadable';
import CampaignWizardSuccessPage from 'containers/CampaignWizardSuccessPage/Loadable';
import ApplyProjectPage from 'containers/ApplyProjectPage/Loadable';
import LeadStep2Form from 'containers/LeadStep2Form/Loadable';
import ApplicationSuccessPage from 'containers/ApplicationSuccessPage/Loadable';
import LeadStep2SuccessPage from 'containers/LeadStep2SuccessPage/Loadable';
import SimPage from 'containers/SimPage/Loadable';
import MonitoringReportPage from 'containers/MonitoringReportPage/Loadable';
import CampaignOwnerKnowledgePage from 'containers/CampaignOwnerKnowledgePage/Loadable';
import InvestorEducationKnowledgePage from 'containers/InvestorEducationKnowledgePage/Loadable';
import AucapPage from 'containers/AucapPage/Loadable';
import Annex3Tool from 'containers/Annex3Tool/Loadable';
import CampaignStats from 'containers/CampaignStats/Loadable';
import CampaignShareholders from 'containers/CampaignShareholders/Loadable';
import SpidValidator from 'containers/SpidValidator/Loadable';
import MamaStatsPage from 'containers/MamaStatsPage/Loadable';

/* END MAMACROWD */

/* START MAMACROWD CONSOLE */
import ConsoleHomePage from 'console/containers/ConsoleHomePage/Loadable';
import ConsoleListCampaigns from 'console/containers/ConsoleListCampaigns/Loadable';
import ConsoleCampaignPage from 'console/containers/ConsoleCampaignPage/Loadable';
import ConsoleCampaignStatsPage from 'console/containers/ConsoleCampaignStatsPage/Loadable';
import ConsoleNewCampaign from 'console/components/ConsoleNewCampaign/Loadable';
import ConsoleCampaignOrders from 'console/containers/ConsoleCampaignOrders/Loadable';
import ConsoleCampaignSim from 'console/containers/ConsoleCampaignSim/Loadable';
import ConsoleCampaignBilling from 'console/containers/ConsoleCampaignBilling/Loadable';
import ConsoleCampaignAnnex3Tool from 'console/containers/ConsoleCampaignAnnex3Tool/Loadable';
import ConsoleCampaignListEarlyBird from 'console/containers/ConsoleCampaignListEarlyBird/Loadable';
import ConsoleListFollowers from 'console/containers/ConsoleListFollowers/Loadable';
import ConsoleListCompanies from 'console/containers/ConsoleListCompanies/Loadable';
import ConsoleCompanyPage from 'console/containers/ConsoleCompanyPage/Loadable';
import ConsoleCompanyMonitoringPage from 'console/containers/ConsoleCompanyMonitoringPage/Loadable';
import ConsoleChamberDocPage from 'console/containers/ConsoleChamberDocPage/Loadable';
import ConsoleLatePaymentsPage from 'console/containers/ConsoleLatePaymentsPage/Loadable';
import ConsoleListOrders from 'console/containers/ConsoleListOrders/Loadable';
import ConsoleListIncompleteOrders from 'console/containers/ConsoleListIncompleteOrders/Loadable';
import ConsoleOrderPage from 'console/containers/ConsoleOrderPage/Loadable';
import ConsoleAdminPage from 'console/containers/ConsoleAdminPage/Loadable';
import ConsoleInvestmentBankOrderPage from 'console/containers/ConsoleInvestmentBankOrderPage/Loadable';
import ConsoleBillingPage from 'console/containers/ConsoleBillingPage/Loadable';
import ConsoleListUsers from 'console/containers/ConsoleListUsers/Loadable';
import ConsoleNewUser from 'console/components/ConsoleNewUser/Loadable';
import ConsoleUserPage from 'console/containers/ConsoleUserPage/Loadable';
import ConsoleListPartners from 'console/containers/ConsoleListPartners/Loadable';
import ConsolePartnerPage from 'console/containers/ConsolePartnerPage/Loadable';
import ConsoleListTestimonials from 'console/containers/ConsoleListTestimonials/Loadable';
import ConsoleTestimonialPage from 'console/containers/ConsoleTestimonialPage/Loadable';
import ConsoleListArticles from 'console/containers/ConsoleListArticles/Loadable';
import ConsoleArticlePage from 'console/containers/ConsoleArticlePage/Loadable';
import ConsoleListMessages from 'console/containers/ConsoleListMessages/Loadable';
import ConsoleMessagePage from 'console/containers/ConsoleMessagePage/Loadable';
import ConsoleListPages from 'console/containers/ConsoleListPages/Loadable';
import ConsolePage from 'console/containers/ConsolePage/Loadable';
import ConsoleListPress from 'console/containers/ConsoleListPress/Loadable';
import ConsolePressPage from 'console/containers/ConsolePressPage/Loadable';
import ConsoleListBanners from 'console/containers/ConsoleListBanners/Loadable';
import ConsoleBannerPage from 'console/containers/ConsoleBannerPage/Loadable';
import ConsoleGuidesPage from 'console/components/ConsoleGuidesPage/Loadable';
import ConsoleStatisticsPage from 'console/containers/ConsoleStatisticsPage/Loadable';
import ConsoleListCompetitors from 'console/containers/ConsoleListCompetitors/Loadable';
import ConsoleCompetitorCampaignForm from 'console/containers/ConsoleCompetitorCampaignForm/Loadable';
import ConsoleCrmEmailForm from 'console/containers/ConsoleCrmEmailForm/Loadable';
import ConsoleMktEmailPage from 'console/containers/ConsoleMktEmailPage/Loadable';
import ConsoleEmailServiceStatus from 'console/containers/ConsoleEmailServiceStatus/Loadable';
import ConsoleAssetsPage from 'console/components/ConsoleAssetsPage/Loadable';
import ConsoleSettingsForm from 'console/containers/ConsoleSettingsForm/Loadable';
import ConsoleListSettingsPage from 'console/containers/ConsoleListSettingsPage/Loadable';
import ConsoleListFaq from 'console/containers/ConsoleListFaq/Loadable';
import ConsoleFaqPage from 'console/containers/ConsoleFaqPage/Loadable';
import ConsoleListIndustries from 'console/containers/ConsoleListIndustries/Loadable';
import ConsoleIndustryPage from 'console/containers/ConsoleIndustryPage/Loadable';
import ConsoleUrlChecker from 'console/containers/ConsoleUrlChecker/Loadable';
import ConsoleListCampaignApplication from 'console/containers/ConsoleListCampaignApplication/Loadable';
import ConsoleCampaignApplication from 'console/containers/ConsoleCampaignApplication/Loadable';
import ConsoleCampaignApplicationDealPage from 'containers/ConsoleCampaignApplicationDealPage/Loadable';
/* END MAMACROWD CONSOLE */

/* START LANDING */
import LandingAds from 'landing/components/LandingAds/Loadable';
import LandingB2C from 'landing/components/LandingB2C/Loadable';
/* END LANDING */

import { changeLocale } from 'containers/LanguageProvider/actions';

import User from 'utils/User';
import history from 'utils/history';
import LocalStorage from 'utils/localStorage';
import ENUMUserPermissions from 'utils/Enums/UserPermissions';
import { trackPageView } from 'utils/trackers';
import {
  DOMAIN_URL,
  HOME_PAGE_ROUTE,
  CAMPAIGNS_ROUTE,
  CAMPAIGN_INVESTMENT_ORDER_SUCCESS_ROUTE,
  CAMPAIGN_INVESTMENT_ORDER_ROUTE,
  PISP_SUCCESS_PAGE_ROUTE,
  PISP_FAIL_PAGE_ROUTE,
  PISP_PAGE_ROUTE,
  CAMPAIGN_ROUTE,
  EQUITY_CALCULATION_ROUTE,
  FAQ_ROUTE,
  NEWS_PAGE_ROUTE,
  BLOG_ARTICLE_ROUTE,
  PRESS_ROUTE,
  SUCCESS_STORIES_ROUTE,
  INDEX_KNOWLEDGE_INVESTOR_ROUTE,
  KNOWLEDGE_INVESTOR_ROUTE,
  APPLY_PAGE_ROUTE,
  APPLY_NEW_PROJECT_EQUITY_ROUTE,
  APPLY_PROJECT_EQUITY_ROUTE,
  APPLY_SUCCESS_PROJECT_ROUTE,
  APPLY_STEP_2_SUCCESS_PROJECT_ROUTE,
  PAGE_ROUTE,
  ME_PORTFOLIO_ROUTE,
  ME_PORTFOLIO_DETAIL_ROUTE,
  ME_PROFILE_ROUTE,
  ME_CAMPAIGNS_ROUTE,
  ME_WATCHLIST_ROUTE,
  ME_UPLOAD_DOCUMENT_ROUTE,
  ME_CHANGE_PASSWORD_ROUTE,
  ME_CAMPAIGN_SHAREHOLDERS_ROUTE,
  ME_CAMPAIGN_ANCHORS_ROUTE,
  ME_CAMPAIGN_COMMUNICATIONS_ROUTE,
  ME_CAMPAIGN_TAX_BONUS_ROUTE,
  ME_CAMPAIGN_EQUITY_CALCULATOR_PREVIEW_ROUTE,
  ME_CAMPAIGN_PREVIEW_ROUTE,
  // CAMPAIGN_WIZARD_CREATE_ROUTE,
  ME_CAMPAIGN_WIZARD_ROUTE,
  ME_CAMPAIGN_WIZARD_SUCCESS_ROUTE,
  ME_CAMPAIGN_STATS_ROUTE,
  ME_SPID_VALIDATOR_ROUTE,
  SIM_PAGE_ROUTE,
  INDEX_KNOWLEDGE_OFFEROR_SME_ROUTE,
  KNOWLEDGE_OFFEROR_SME_ROUTE,
  INDEX_KNOWLEDGE_OFFEROR_RE_ROUTE,
  KNOWLEDGE_OFFEROR_RE_ROUTE,
  AUCAP_TOOL_ROUTE,
  ANNEX_3_TOOL_ROUTE,
  LOGIN_ROUTE,
  TOKEN_LOGIN_ROUTE,
  SPID_LOGIN_ROUTE,
  SPID_LOGIN_SUCCESS_ROUTE,
  REGISTER_ROUTE,
  FORGOT_ROUTE,
  TEAM_MEMBER_REGISTER_ROUTE,
  ACTIVATION_MAIL_ROUTE,
  SOCIAL_AUTHORIZATION_LOGIN_ROUTE,
  LINKEDIN_AUTHORIZATION_LOGIN_ROUTE,
  GOOGLE_AUTHORIZATION_LOGIN_ROUTE,
  FACEBOOK_AUTHORIZATION_LOGIN_ROUTE,
  SOCIAL_AUTHORIZATION_REGISTER_ROUTE,
  LINKEDIN_AUTHORIZATION_REGISTER_ROUTE,
  LINKEDIN_AUTHORIZATION_ACCESS_ROUTE,
  GOOGLE_AUTHORIZATION_REGISTER_ROUTE,
  FACEBOOK_AUTHORIZATION_REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  ERROR_ROUTE,
  NOT_FOUND_PAGE_ROUTE,
  ACCESS_DENIED_PAGE_ROUTE,
  LANDING_ADS_ROUTE,
  LANDING_B2C_ROUTE,
  CONSOLE_PREFIX,
  CONSOLE_HOME_PAGE_ROUTE,
  CONSOLE_LIST_CAMPAIGNS_ROUTE,
  CONSOLE_STATISTIC_CAMPAIGN_ROUTE,
  CONSOLE_CAMPAIGN_ORDERS_ROUTE,
  CONSOLE_CAMPAIGN_INCOMPLETE_ORDERS_ROUTE,
  CONSOLE_CAMPAIGN_EARLY_BIRD_ROUTE,
  CONSOLE_CAMPAIGN_FOLLOWERS_ROUTE,
  CONSOLE_CAMPAIGN_SIM_ROUTE,
  CONSOLE_CREATE_CAMPAIGN_ROUTE,
  CONSOLE_CAMPAIGN_ROUTE,
  CONSOLE_CAMPAIGN_INVESTORS_LIST_ROUTE,
  CONSOLE_CAMPAIGN_ANNEX_3_ROUTE,
  CONSOLE_CAMPAIGN_INVESTORS_LIST_DRAFT_ROUTE,
  CONSOLE_LIST_COMPANIES_ROUTE,
  CONSOLE_CREATE_COMPANY_ROUTE,
  CONSOLE_COMPANY_ROUTE,
  CONSOLE_LATE_PAYMENTS_ROUTE,
  CONSOLE_LIST_ORDERS_ROUTE,
  CONSOLE_ORDER_ROUTE,
  CONSOLE_LIST_INCOMPLETE_ORDERS_ROUTE,
  CONSOLE_INVESTMENT_BANK_ORDER_ROUTE,
  CONSOLE_OPERATIONS_PAGE_ROUTE,
  CONSOLE_LIST_USERS_ROUTE,
  CONSOLE_CREATE_USER_ROUTE,
  CONSOLE_USER_ROUTE,
  CONSOLE_LIST_PARTNERS_ROUTE,
  CONSOLE_CREATE_PARTNER_ROUTE,
  CONSOLE_PARTNER_ROUTE,
  CONSOLE_LIST_TESTIMONIALS_ROUTE,
  CONSOLE_CREATE_TESTIMONIALS_ROUTE,
  CONSOLE_TESTIMONIALS_ROUTE,
  CONSOLE_LIST_ARTICLES_ROUTE,
  CONSOLE_CREATE_ARTICLE_ROUTE,
  CONSOLE_ARTICLE_ROUTE,
  CONSOLE_LIST_MESSAGES_ROUTE,
  CONSOLE_CREATE_MESSAGE_ROUTE,
  CONSOLE_MESSAGE_ROUTE,
  CONSOLE_LIST_BANNERS_ROUTE,
  CONSOLE_CREATE_BANNER_ROUTE,
  CONSOLE_BANNER_ROUTE,
  CONSOLE_LIST_PAGES_ROUTE,
  CONSOLE_CREATE_PAGE_ROUTE,
  CONSOLE_PAGE_ROUTE,
  CONSOLE_LIST_PRESS_ROUTE,
  CONSOLE_CREATE_PRESS_ROUTE,
  CONSOLE_PRESS_ROUTE,
  CONSOLE_STATISTICS_ROUTE,
  CONSOLE_LIST_COMPETITORS_ROUTE,
  CONSOLE_CREATE_COMPETITORS_CAMPAIGN_ROUTE,
  CONSOLE_COMPETITORS_CAMPAIGN_ROUTE,
  CONSOLE_COMPANY_MONITORING_ROUTE,
  CONSOLE_CUSTOM_EMAIL_ROUTE,
  CONSOLE_CUSTOM_MKT_EMAIL_ROUTE,
  CONSOLE_EMAIL_SERVICE_STATUS_ROUTE,
  CONSOLE_MEDIA_PAGE_ROUTE,
  CONSOLE_LIST_SETTINGS_ROUTE,
  CONSOLE_CREATE_SETTING_ROUTE,
  CONSOLE_SETTING_ROUTE,
  CONSOLE_LIST_FAQ_ROUTE,
  CONSOLE_CREATE_FAQ_ROUTE,
  CONSOLE_FAQ_ROUTE,
  CONSOLE_LIST_INDUSTRIES_ROUTE,
  CONSOLE_CREATE_INDUSTRY_ROUTE,
  CONSOLE_INDUSTRY_ROUTE,
  CONSOLE_GUIDES_PAGE_ROUTE,
  CONSOLE_URL_CHECKER_ROUTE,
  CONSOLE_CAMPAIGN_APPLICATION_ROUTE,
  CONSOLE_LIST_CAMPAIGN_APPLICATION_ROUTE,
  CONSOLE_CAMPAIGN_EQUITY_CALCULATOR_PREVIEW_ROUTE,
  CONSOLE_CAMPAIGN_BILLING_ROUTE,
  CONSOLE_BILLING_ROUTE,
  OLD_HOME_PAGE_ROUTE,
  OLD_CAMPAIGN_PAGE_ROUTE,
  OLD_CAMPAIGNS_PAGE_ROUTE,
  ME_COMPANY_MONITORING_ROUTE,
  ME_COMPANY_MONITORING_FORM_ROUTE,
  CONSOLE_CHAMBER_DOC_PAGE_ROUTE,
  STATISTICS_ROUTE,
  CONSOLE_CAMPAIGN_APPLICATION_HUBSPOT_DEAL_ROUTE,
  MONITORING_PAGE_ROUTE,

  // REGEX FOR HOME PAGE:
  LOGIN_DEFAULT_REDIRECT_URL_REGEXP,
  APPLY_NEW_PROJECT_EQUITY_ROUTE_STEP_2,
  VIEW_APPLIED_PROJECT_ROUTE,
} from 'utils/constants';

import saga from './saga';
import { loadUser } from './actions';
import { makeSelectLocation, makeSelectUserLogged } from './selectors';
import { useStyle } from './App.style';
import messages from './messages';

export function App({ initUser, userLogged, location, intl, onLocaleToggle }) {
  useInjectSaga({ key: 'app', saga });

  const [openIEDialog, setOpenIEDialog] = useState(false);
  const [language, setLanguage] = useState('');
  const [url, setUrl] = useState('');

  const classes = useStyle();

  const isSec = window.location.pathname.includes(CONSOLE_PREFIX);

  const trackingPageView = () => {
    if (history && history.location.pathname.match(/^\/(it|en)\//i) && !isSec) {
      const { pathname, search, hash } = history.location;
      trackPageView(`${pathname}${search}${hash}`);
    }
  };

  useEffect(() => {
    trackingPageView();
    history.listen(trackingPageView);
  }, [history]);

  useEffect(() => {
    initUser();
    if (userLogged && !User.hasTokenExpired()) {
      Sentry.setUser({
        id: User.getUserProp('id'),
        username: `${User.getUserProp('firstName')} ${User.getUserProp(
          'lastName',
        )}`,
        email: User.getUserProp('email'),
      });
      const userLang = User.getUserProp('language');
      if (process.env.ENABLE_MULTILANGUAGE === 'true' && userLang) {
        onLocaleToggle(userLang);
      }
      if (LOGIN_DEFAULT_REDIRECT_URL_REGEXP.test(window.location.pathname)) {
        history.push(CAMPAIGNS_ROUTE);
      }
    } else {
      Sentry.setUser(null);
    }
  }, [initUser, userLogged]);

  useEffect(() => {
    // if (!location.target && location.pathname && !isSec) {
    //   trackPageView(`${location.pathname}${location.search}${location.hash}`);
    // }

    const regExp = /^\/(en|it)\//;
    const match = regExp.exec(location.pathname);
    const lang = match && match.length > 0 ? match[1] : '';
    const path = `${DOMAIN_URL}${location.pathname.replace(`/${lang}`, '/**')}`;
    setLanguage(lang);
    setUrl(path);
  }, [location.pathname, location.search, location.hash]);

  useEffect(() => {
    if (window && window.navigator) {
      const { userAgent } = window.navigator;
      if (
        userAgent.indexOf('MSIE') >= 0 ||
        userAgent.indexOf('Trident') >= 0 ||
        userAgent.indexOf('Edge') >= 0
      ) {
        if (!LocalStorage.getItem('popupMessage-oldBrowserIE')) {
          setOpenIEDialog(true);
        }
      }
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, []);

  return (
    <>
      <Helmet
        titleTemplate="%s | Mamacrowd"
        defaultTitle="Mamacrowd"
        htmlAttributes={{ lang: language }}
      >
        <link rel="alternate" hrefLang="it" href={url.replace('**', 'it')} />
        <link rel="alternate" hrefLang="en" href={url.replace('**', 'en')} />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={url.replace('/**', '')}
        />
        <meta
          name="description"
          content={intl.formatMessage(messages.SEO_DESCRIPTION)}
        />
        <meta property="fb:app_id" content={process.env.FACEBOOK_CLIENT_ID} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Mamacrowd" />
        <meta
          property={`og:locale${language === 'it' ? '' : ':alternate'}`}
          content="it_IT"
        />
        <meta
          property={`og:locale${language === 'en' ? '' : ':alternate'}`}
          content="en_US"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_ASSETS_DOMAIN}/PUBLIC/Immagini/shareimg_mama.png`}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <LocalizedRouter
        RouterComponent={ConnectedRouter}
        history={history}
        defaultLanguage={LocalStorage.getItem('user-language') || 'it'}
      >
        <UtmProvider>
          <Box className={classes.appPageContainer}>
            <ScrollRestoration />
            <InternetExplorerDialog open={openIEDialog} />
            <LocalizedSwitch>
              {/* COMEHOME - redirect from /home to /comehome  */}
              {/* Remove when campaign close */}
              <Redirect
                exact
                from="/home"
                to={{
                  pathname: '/comehome',
                  search: window.location.search,
                }}
              />
              <Redirect
                exact
                from="/project/home"
                to={{
                  pathname: '/project/comehome',
                  search: window.location.search,
                }}
              />
              {/* AZIMUT - redirect from /project/venture-capital-azimut-alicrowd-iv to /project/alicrowd-iv  */}
              {/* Remove when campaign close */}
              <Redirect
                exact
                from="/project/venture-capital-azimut-alicrowd-iv"
                to={{
                  pathname: '/project/alicrowd-iv',
                  search: window.location.search,
                }}
              />

              {/* APPLICATION ROUTES (HEADER + FOOTER) */}
              <PublicRoute
                Layout={WebLayout}
                exact
                path={HOME_PAGE_ROUTE}
                MainComponent={HomePage}
              />
              <PublicRoute
                exact
                Layout={WebLayout}
                path={CAMPAIGNS_ROUTE}
                MainComponent={CampaignsPage}
              />
              <PublicRoute
                Layout={LiteLayout}
                path={CAMPAIGN_INVESTMENT_ORDER_SUCCESS_ROUTE}
                exact
                MainComponent={InvestmentSuccessPage}
                loggedRoute
              />
              <PublicRoute
                Layout={LiteLayout}
                path={CAMPAIGN_INVESTMENT_ORDER_ROUTE}
                MainComponent={InvestmentPage}
              />
              <PublicRoute
                Layout={LiteLayout}
                path={PISP_SUCCESS_PAGE_ROUTE}
                MainComponent={PispSuccessPage}
                loggedRoute
                exact
              />
              <PublicRoute
                Layout={LiteLayout}
                path={PISP_FAIL_PAGE_ROUTE}
                MainComponent={PispSuccessPage}
                loggedRoute
                exact
              />
              <PublicRoute
                Layout={LiteLayout}
                path={PISP_PAGE_ROUTE}
                MainComponent={PispPage}
                loggedRoute
                exact
              />
              <PublicRoute
                Layout={WebLayout}
                path={CAMPAIGN_ROUTE}
                exact
                MainComponent={CampaignPage}
              />
              <PublicRoute
                Layout={WebLayout}
                path={EQUITY_CALCULATION_ROUTE}
                exact
                MainComponent={EquityCalculatorPage}
                loggedRoute
              />
              <PublicRoute
                Layout={WebLayout}
                path={FAQ_ROUTE}
                exact
                MainComponent={Faq}
              />
              <PublicRoute
                Layout={WebLayout}
                path={NEWS_PAGE_ROUTE}
                exact
                MainComponent={NewsPage}
              />
              <PublicRoute
                Layout={WebLayout}
                path={BLOG_ARTICLE_ROUTE}
                exact
                MainComponent={BlogArticlePage}
              />
              <PublicRoute
                Layout={WebLayout}
                path={PRESS_ROUTE}
                exact
                MainComponent={PressPage}
              />
              <PublicRoute
                Layout={WebLayout}
                path={SUCCESS_STORIES_ROUTE}
                exact
                MainComponent={SuccessStoriesPage}
              />
              <PublicRoute
                Layout={WebLayout}
                path={INDEX_KNOWLEDGE_INVESTOR_ROUTE}
                MainComponent={InvestorEducationKnowledgePage}
                exact
              />
              <PublicRoute
                Layout={WebLayout}
                path={KNOWLEDGE_INVESTOR_ROUTE}
                MainComponent={InvestorEducationKnowledgePage}
              />
              <PublicRoute
                Layout={WebLayout}
                path={VIEW_APPLIED_PROJECT_ROUTE}
                exact
                MainComponent={CampaignApplicationViewPage}
              />
              <PublicRoute
                Layout={WebLayout}
                path={APPLY_PAGE_ROUTE}
                exact
                MainComponent={RaiseCapitalPage}
              />
              <PublicRoute
                Layout={WebLayout}
                path={APPLY_NEW_PROJECT_EQUITY_ROUTE}
                MainComponent={ApplyProjectPage}
                loggedRoute
                isLead
                exact
              />
              <PublicRoute
                Layout={WebLayout}
                path={APPLY_NEW_PROJECT_EQUITY_ROUTE_STEP_2}
                MainComponent={LeadStep2Form}
                loggedRoute
                isLead
                exact
              />
              <PublicRoute
                Layout={WebLayout}
                path={APPLY_PROJECT_EQUITY_ROUTE}
                MainComponent={ApplyProjectPage}
                loggedRoute
                isLead
                exact
              />
              <PublicRoute
                Layout={WebLayout}
                path={APPLY_STEP_2_SUCCESS_PROJECT_ROUTE}
                MainComponent={LeadStep2SuccessPage}
                loggedRoute
              />
              <PublicRoute
                Layout={WebLayout}
                path={APPLY_SUCCESS_PROJECT_ROUTE}
                MainComponent={ApplicationSuccessPage}
                loggedRoute
              />
              <PublicRoute
                Layout={WebLayout}
                path={STATISTICS_ROUTE}
                MainComponent={MamaStatsPage}
                exact
              />
              <PublicRoute
                Layout={WebLayout}
                path={PAGE_ROUTE}
                exact
                MainComponent={Page}
              />

              {/* ME LAYOUT: EXPIRE TOKEN ADDED */}
              <MeRoute
                Layout={MeLayout}
                layoutProps={{ isPortfolio: true }}
                path={ME_PORTFOLIO_ROUTE}
                exact
                MainComponent={PortfolioPage}
              />
              <MeRoute
                Layout={MeLayout}
                layoutProps={{ isPortfolioDetail: true }}
                path={`${ME_PORTFOLIO_DETAIL_ROUTE}`}
                exact
                MainComponent={PortfolioDetailsPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_PROFILE_ROUTE}
                exact
                MainComponent={AccountPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_CAMPAIGNS_ROUTE}
                exact
                MainComponent={MyCampaignsPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_COMPANY_MONITORING_ROUTE}
                exact
                MainComponent={MyCompanyMonitoringPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_COMPANY_MONITORING_FORM_ROUTE}
                exact
                MainComponent={MyCompanyMonitoringFormPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_WATCHLIST_ROUTE}
                exact
                MainComponent={WatchlistPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_UPLOAD_DOCUMENT_ROUTE}
                exact
                MainComponent={UserUploadForm}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_CHANGE_PASSWORD_ROUTE}
                exact
                MainComponent={ChangePasswordForm}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_CAMPAIGN_PREVIEW_ROUTE}
                exact
                MainComponent={CampaignPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_CAMPAIGN_EQUITY_CALCULATOR_PREVIEW_ROUTE}
                exact
                MainComponent={EquityCalculatorPreviewPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_CAMPAIGN_SHAREHOLDERS_ROUTE}
                exact
                MainComponent={InvestorsTablePage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_CAMPAIGN_ANCHORS_ROUTE}
                exact
                MainComponent={AnchorInvestorsTablePage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_CAMPAIGN_COMMUNICATIONS_ROUTE}
                exact
                MainComponent={UploadDocumentsPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_CAMPAIGN_TAX_BONUS_ROUTE}
                exact
                MainComponent={CampaignTaxBonus}
              />
              {/* <MeRoute
                Layout={MeLayout}
                path={CAMPAIGN_WIZARD_CREATE_ROUTE}
                exact
                MainComponent={CampaignWizardComingSoon}
              /> */}
              <MeRoute
                Layout={MeLayout}
                path={ME_CAMPAIGN_WIZARD_ROUTE}
                exact
                MainComponent={CampaignWizardPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={ME_CAMPAIGN_WIZARD_SUCCESS_ROUTE}
                exact
                MainComponent={CampaignWizardSuccessPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={SIM_PAGE_ROUTE}
                exact
                MainComponent={SimPage}
              />
              <MeRoute
                Layout={MeLayout}
                path={MONITORING_PAGE_ROUTE}
                exact
                MainComponent={MonitoringReportPage}
              />
              <MeRoute
                Layout={WebLayout}
                path={INDEX_KNOWLEDGE_OFFEROR_RE_ROUTE}
                MainComponent={CampaignOwnerKnowledgePage}
                // campaignOwnerRoute
                exact
                isRealEstate
              />
              <MeRoute
                Layout={WebLayout}
                path={KNOWLEDGE_OFFEROR_RE_ROUTE}
                MainComponent={CampaignOwnerKnowledgePage}
                // campaignOwnerRoute
                exact
                isRealEstate
              />
              <MeRoute
                Layout={WebLayout}
                path={INDEX_KNOWLEDGE_OFFEROR_SME_ROUTE}
                MainComponent={CampaignOwnerKnowledgePage}
                // campaignOwnerRoute
                exact
              />
              <MeRoute
                Layout={WebLayout}
                path={KNOWLEDGE_OFFEROR_SME_ROUTE}
                MainComponent={CampaignOwnerKnowledgePage}
                // campaignOwnerRoute
                exact
              />
              <MeRoute
                Layout={WebLayout}
                path={AUCAP_TOOL_ROUTE}
                MainComponent={AucapPage}
              />
              <MeRoute
                Layout={WebLayout}
                path={ANNEX_3_TOOL_ROUTE}
                MainComponent={Annex3Tool}
                campaignOwnerRoute
              />
              <MeRoute
                Layout={WebLayout}
                path={ME_CAMPAIGN_STATS_ROUTE}
                MainComponent={CampaignStats}
                campaignOwnerRoute
              />
              <MeRoute
                Layout={WebLayout}
                path={ME_SPID_VALIDATOR_ROUTE}
                MainComponent={SpidValidator}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={SPID_LOGIN_SUCCESS_ROUTE}
                MainComponent={SpidSuccessPage}
              />

              {/* ERROR ROUTES */}
              <PublicRoute
                Layout={WebLayout}
                path={ERROR_ROUTE}
                MainComponent={ErrorPage}
              />

              {/* LITE ROUTES (NO HEADER AND FOOTER) */}
              <PublicRoute
                Layout={AuthLayout}
                path={LOGIN_ROUTE}
                MainComponent={LoginPage}
                exact
              />
              <PublicRoute
                Layout={AuthLayout}
                path={TOKEN_LOGIN_ROUTE}
                MainComponent={TokenLoginPage}
                exact
              />
              <PublicRoute
                Layout={AuthLayout}
                path={SPID_LOGIN_ROUTE}
                MainComponent={LoginPage}
                exact
              />
              <PublicRoute
                Layout={AuthLayout}
                path={REGISTER_ROUTE}
                MainComponent={RegisterPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={FORGOT_ROUTE}
                MainComponent={ForgotPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={ACTIVATION_MAIL_ROUTE}
                MainComponent={ActivationMailPage}
                exact
              />
              <PublicRoute
                Layout={AuthLayout}
                path={SOCIAL_AUTHORIZATION_LOGIN_ROUTE}
                MainComponent={SocialAuthorizationLoginPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={LINKEDIN_AUTHORIZATION_LOGIN_ROUTE}
                MainComponent={LinkedinAuthorizationLoginPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={GOOGLE_AUTHORIZATION_LOGIN_ROUTE}
                MainComponent={GoogleAuthorizationLoginPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={FACEBOOK_AUTHORIZATION_LOGIN_ROUTE}
                MainComponent={FacebookAuthorizationLoginPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={SOCIAL_AUTHORIZATION_REGISTER_ROUTE}
                MainComponent={SocialAuthorizationRegisterPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={LINKEDIN_AUTHORIZATION_REGISTER_ROUTE}
                MainComponent={LinkedinAuthorizationRegisterPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={LINKEDIN_AUTHORIZATION_ACCESS_ROUTE}
                MainComponent={LinkedinAuthorizationRegisterPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={GOOGLE_AUTHORIZATION_REGISTER_ROUTE}
                MainComponent={GoogleAuthorizationRegisterPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={FACEBOOK_AUTHORIZATION_REGISTER_ROUTE}
                MainComponent={FacebookAuthorizationRegisterPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={RESET_PASSWORD_ROUTE}
                MainComponent={ResetPasswordPage}
              />
              <PublicRoute
                Layout={AuthLayout}
                path={TEAM_MEMBER_REGISTER_ROUTE}
                MainComponent={LinkedinTeamMemberRegisterPage}
              />
              <PublicRoute
                Layout={LandingLayout}
                path={LANDING_ADS_ROUTE}
                MainComponent={LandingAds}
                showFooter
                exact
              />
              <PublicRoute
                Layout={LandingLayout}
                path={LANDING_B2C_ROUTE}
                MainComponent={LandingB2C}
                exact
              />

              {/* PROTECTED ROUTES */}
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_HOME_PAGE_ROUTE}`}
                permissions=""
                MainComponent={ConsoleHomePage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_CAMPAIGNS_ROUTE}`}
                permissions={ENUMUserPermissions.Campaign}
                MainComponent={ConsoleListCampaigns}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_STATISTIC_CAMPAIGN_ROUTE}`}
                permissions={ENUMUserPermissions.Campaign}
                MainComponent={ConsoleCampaignStatsPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_ORDERS_ROUTE}`}
                permissions={ENUMUserPermissions.InvestmentOrder}
                MainComponent={ConsoleCampaignOrders}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_INCOMPLETE_ORDERS_ROUTE}`}
                permissions={ENUMUserPermissions.InvestmentOrder}
                MainComponent={ConsoleListIncompleteOrders}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_EARLY_BIRD_ROUTE}`}
                permissions={ENUMUserPermissions.EarlyBird}
                MainComponent={ConsoleCampaignListEarlyBird}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_FOLLOWERS_ROUTE}`}
                permissions={ENUMUserPermissions.Follow}
                MainComponent={ConsoleListFollowers}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_SIM_ROUTE}`}
                permissions={ENUMUserPermissions.Sim}
                MainComponent={ConsoleCampaignSim}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_BILLING_ROUTE}`}
                permissions={ENUMUserPermissions.Billing}
                MainComponent={ConsoleCampaignBilling}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_CAMPAIGN_ROUTE}`}
                permissions={ENUMUserPermissions.Campaign}
                MainComponent={ConsoleNewCampaign}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_ROUTE}`}
                permissions={ENUMUserPermissions.Campaign}
                MainComponent={ConsoleCampaignPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_INVESTORS_LIST_ROUTE}`}
                permissions={ENUMUserPermissions.InvestmentOrder}
                MainComponent={CampaignShareholders}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_ANNEX_3_ROUTE}`}
                permissions={ENUMUserPermissions.Campaign}
                MainComponent={ConsoleCampaignAnnex3Tool}
                isInSec
              />
              <ProtectedRoute
                Layout={MeLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_EQUITY_CALCULATOR_PREVIEW_ROUTE}`}
                exact
                MainComponent={EquityCalculatorPreviewPage}
                isSec
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_INVESTORS_LIST_DRAFT_ROUTE}`}
                permissions={ENUMUserPermissions.InvestmentOrder}
                MainComponent={CampaignShareholders}
                isDraft
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_COMPANIES_ROUTE}`}
                permissions={ENUMUserPermissions.Company}
                MainComponent={ConsoleListCompanies}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_COMPANY_ROUTE}`}
                permissions={ENUMUserPermissions.Company}
                MainComponent={ConsoleCompanyPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_COMPANY_ROUTE}`}
                permissions={ENUMUserPermissions.Company}
                MainComponent={ConsoleCompanyPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_COMPANY_MONITORING_ROUTE}`}
                permissions={ENUMUserPermissions.Company}
                MainComponent={ConsoleCompanyMonitoringPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CHAMBER_DOC_PAGE_ROUTE}`}
                permissions={ENUMUserPermissions.Company}
                MainComponent={ConsoleChamberDocPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LATE_PAYMENTS_ROUTE}`}
                permissions={ENUMUserPermissions.InvestmentOrder}
                MainComponent={ConsoleLatePaymentsPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_ORDERS_ROUTE}`}
                permissions={ENUMUserPermissions.InvestmentOrder}
                MainComponent={ConsoleListOrders}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_ORDER_ROUTE}`}
                permissions={ENUMUserPermissions.InvestmentOrder}
                MainComponent={ConsoleOrderPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_INCOMPLETE_ORDERS_ROUTE}`}
                permissions={ENUMUserPermissions.InvestmentOrder}
                MainComponent={ConsoleListIncompleteOrders}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_INVESTMENT_BANK_ORDER_ROUTE}`}
                permissions={ENUMUserPermissions.Report}
                MainComponent={ConsoleInvestmentBankOrderPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_OPERATIONS_PAGE_ROUTE}`}
                permissions={ENUMUserPermissions.Notification}
                MainComponent={ConsoleAdminPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_BILLING_ROUTE}`}
                permissions={ENUMUserPermissions.Billing}
                MainComponent={ConsoleBillingPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_USERS_ROUTE}`}
                permissions={ENUMUserPermissions.User}
                MainComponent={ConsoleListUsers}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_USER_ROUTE}`}
                permissions={ENUMUserPermissions.User}
                MainComponent={ConsoleNewUser}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_USER_ROUTE}`}
                permissions={ENUMUserPermissions.User}
                MainComponent={ConsoleUserPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_PARTNERS_ROUTE}`}
                permissions={ENUMUserPermissions.Partner}
                MainComponent={ConsoleListPartners}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_PARTNER_ROUTE}`}
                permissions={ENUMUserPermissions.Partner}
                MainComponent={ConsolePartnerPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_PARTNER_ROUTE}`}
                permissions={ENUMUserPermissions.Partner}
                MainComponent={ConsolePartnerPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_TESTIMONIALS_ROUTE}`}
                permissions={ENUMUserPermissions.Testimonial}
                MainComponent={ConsoleListTestimonials}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_TESTIMONIALS_ROUTE}`}
                permissions={ENUMUserPermissions.Testimonial}
                MainComponent={ConsoleTestimonialPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_TESTIMONIALS_ROUTE}`}
                permissions={ENUMUserPermissions.Testimonial}
                MainComponent={ConsoleTestimonialPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_ARTICLES_ROUTE}`}
                permissions={ENUMUserPermissions.News}
                MainComponent={ConsoleListArticles}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_ARTICLE_ROUTE}`}
                permissions={ENUMUserPermissions.News}
                MainComponent={ConsoleArticlePage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_ARTICLE_ROUTE}`}
                permissions={ENUMUserPermissions.News}
                MainComponent={ConsoleArticlePage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_MESSAGES_ROUTE}`}
                permissions={ENUMUserPermissions.WebMessage}
                MainComponent={ConsoleListMessages}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_MESSAGE_ROUTE}`}
                permissions={ENUMUserPermissions.WebMessage}
                MainComponent={ConsoleMessagePage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_MESSAGE_ROUTE}`}
                permissions={ENUMUserPermissions.WebMessage}
                MainComponent={ConsoleMessagePage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_BANNERS_ROUTE}`}
                permissions={ENUMUserPermissions.HpBanner}
                MainComponent={ConsoleListBanners}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_BANNER_ROUTE}`}
                permissions={ENUMUserPermissions.HpBanner}
                MainComponent={ConsoleBannerPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_BANNER_ROUTE}`}
                permissions={ENUMUserPermissions.HpBanner}
                MainComponent={ConsoleBannerPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_PAGES_ROUTE}`}
                permissions={ENUMUserPermissions.Page}
                MainComponent={ConsoleListPages}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_PAGE_ROUTE}`}
                permissions={ENUMUserPermissions.Page}
                MainComponent={ConsolePage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_PAGE_ROUTE}`}
                permissions={ENUMUserPermissions.Page}
                MainComponent={ConsolePage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_PRESS_ROUTE}`}
                permissions={ENUMUserPermissions.Press}
                MainComponent={ConsoleListPress}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_PRESS_ROUTE}`}
                permissions={ENUMUserPermissions.Press}
                MainComponent={ConsolePressPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_PRESS_ROUTE}`}
                permissions={ENUMUserPermissions.Press}
                MainComponent={ConsolePressPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_STATISTICS_ROUTE}`}
                permissions={ENUMUserPermissions.DataBoard}
                MainComponent={ConsoleStatisticsPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_COMPETITORS_ROUTE}`}
                permissions={ENUMUserPermissions.Competitors}
                MainComponent={ConsoleListCompetitors}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_COMPETITORS_CAMPAIGN_ROUTE}`}
                permissions={ENUMUserPermissions.Competitors}
                MainComponent={ConsoleCompetitorCampaignForm}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_COMPETITORS_CAMPAIGN_ROUTE}`}
                permissions={ENUMUserPermissions.Competitors}
                MainComponent={ConsoleCompetitorCampaignForm}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CUSTOM_EMAIL_ROUTE}`}
                permissions={ENUMUserPermissions.DataBoard}
                MainComponent={ConsoleCrmEmailForm}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CUSTOM_MKT_EMAIL_ROUTE}`}
                permissions={ENUMUserPermissions.DataBoard}
                MainComponent={ConsoleMktEmailPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_EMAIL_SERVICE_STATUS_ROUTE}`}
                permissions={ENUMUserPermissions.DataBoard}
                MainComponent={ConsoleEmailServiceStatus}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_MEDIA_PAGE_ROUTE}`}
                permissions=""
                MainComponent={ConsoleAssetsPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_SETTINGS_ROUTE}`}
                permissions=""
                MainComponent={ConsoleListSettingsPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_SETTING_ROUTE}`}
                permissions=""
                MainComponent={ConsoleSettingsForm}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_SETTING_ROUTE}`}
                permissions=""
                MainComponent={ConsoleSettingsForm}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_FAQ_ROUTE}`}
                permissions={ENUMUserPermissions.Faq}
                MainComponent={ConsoleListFaq}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_FAQ_ROUTE}`}
                permissions={ENUMUserPermissions.Faq}
                MainComponent={ConsoleFaqPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_FAQ_ROUTE}`}
                permissions={ENUMUserPermissions.Faq}
                MainComponent={ConsoleFaqPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_INDUSTRIES_ROUTE}`}
                permissions={ENUMUserPermissions.Industry}
                MainComponent={ConsoleListIndustries}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CREATE_INDUSTRY_ROUTE}`}
                permissions={ENUMUserPermissions.Industry}
                MainComponent={ConsoleIndustryPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_INDUSTRY_ROUTE}`}
                permissions={ENUMUserPermissions.Industry}
                MainComponent={ConsoleIndustryPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_GUIDES_PAGE_ROUTE}`}
                permissions=""
                MainComponent={ConsoleGuidesPage}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_URL_CHECKER_ROUTE}`}
                permissions=""
                MainComponent={ConsoleUrlChecker}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_LIST_CAMPAIGN_APPLICATION_ROUTE}`}
                permissions=""
                MainComponent={ConsoleListCampaignApplication}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_APPLICATION_ROUTE}`}
                permissions=""
                MainComponent={ConsoleCampaignApplication}
              />
              <ProtectedRoute
                exact
                Layout={ConsoleLayout}
                path={`${CONSOLE_PREFIX}${CONSOLE_CAMPAIGN_APPLICATION_HUBSPOT_DEAL_ROUTE}`}
                permissions=""
                MainComponent={ConsoleCampaignApplicationDealPage}
              />
              {/* ACCESS DENIED */}
              <PublicRoute
                exact
                Layout={WebLayout}
                path={ACCESS_DENIED_PAGE_ROUTE}
                MainComponent={AccessDeniedPage}
              />
              {/* NOT FOUND */}
              <PublicRoute
                exact
                Layout={WebLayout}
                path={NOT_FOUND_PAGE_ROUTE}
                MainComponent={NotFoundPage}
              />
              {/* force redirect to / when url contains only /it or /en (avoiding redirect to project) */}
              {/* <Redirect exact from="/it" to={`/${window.location.search}`} /> */}
              {/* <Redirect exact from="/en" to={`/${window.location.search}`} /> */}

              <Redirect
                exact
                from={OLD_CAMPAIGN_PAGE_ROUTE}
                to={{
                  pathname: CAMPAIGN_ROUTE,
                  search: window.location.search,
                }}
              />
              <Redirect
                exact
                from={OLD_CAMPAIGNS_PAGE_ROUTE}
                to={{
                  pathname: CAMPAIGNS_ROUTE,
                  search: window.location.search,
                }}
              />
              <Redirect
                exact
                from={OLD_HOME_PAGE_ROUTE}
                to={{
                  pathname: CAMPAIGN_ROUTE,
                  search: window.location.search,
                }}
              />
              <PublicRoute
                path="*"
                Layout={WebLayout}
                MainComponent={NotFoundPage}
              />
            </LocalizedSwitch>
          </Box>
        </UtmProvider>
      </LocalizedRouter>
    </>
  );
}

App.propTypes = {
  initUser: PropTypes.func.isRequired,
  userLogged: PropTypes.bool.isRequired,
  location: PropTypes.object,
  intl: PropTypes.any,
  onLocaleToggle: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  userLogged: makeSelectUserLogged(),
});

function mapDispatchToProps(dispatch) {
  return {
    initUser: () => {
      dispatch(loadUser());
    },
    onLocaleToggle: language => dispatch(changeLocale(language)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(App);
