/*
 * ApplyLeadStep2Preview Messages
 *
 * This contains all the text for the ApplyLeadStep2Preview component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ApplyLeadStep2Preview';

export default defineMessages({
  brevetti_e_stato_approvazione: {
    id: `${scope}.brevetti_e_stato_approvazione.message`,
    defaultMessage: 'Brevetti e stato approvazione: ',
  },
  burn_rate_mensile: {
    id: `${scope}.burn_rate_mensile.message`,
    defaultMessage: 'Burn rate mensile: ',
  },
  business_metrics: {
    id: `${scope}.business_metrics.message`,
    defaultMessage: 'Business Metrics: ',
  },
  business_model: {
    id: `${scope}.business_model.message`,
    defaultMessage: 'Business Model: ',
  },
  coinvolgimento_e_dedizione_a_tempo_pieno_dei_fondatori_nel_progetto: {
    id: `${scope}.coinvolgimento_e_dedizione_a_tempo_pieno_dei_fondatori_nel_progetto.message`,
    defaultMessage:
      'Coinvolgimento e dedizione a tempo pieno dei fondatori nel progetto: ',
  },
  date_e_valutazione_pre_money_post_money: {
    id: `${scope}.date_e_valutazione_pre_money_post_money.message`,
    defaultMessage: 'Date e valutazione pre money post money: ',
  },
  descrizione_del_modello_di_business: {
    id: `${scope}.descrizione_del_modello_di_business.message`,
    defaultMessage: 'Descrizione del modello di business: ',
  },

  descrizione_del_team_founder_completa: {
    id: `${scope}.descrizione_del_team_founder_completa.message`,
    defaultMessage: 'Descrizione del team founder completa: ',
  },
  dettaglio_dei_dipendenti_e_consulenti: {
    id: `${scope}.dettaglio_dei_dipendenti_e_consulenti.message`,
    defaultMessage: 'Dettaglio dei dipendenti e consulenti: ',
  },
  disponibilita_di_cassa: {
    id: `${scope}.disponibilita_di_cassa.message`,
    defaultMessage: 'Disponibilita di cassa: ',
  },
  founders: {
    id: `${scope}.founders.message`,
    defaultMessage: 'Founders: ',
  },
  linkedin_profili_dei_founder_e_key_people_del_team: {
    id: `${scope}.linkedin_profili_dei_founder_e_key_people_del_team.message`,
    defaultMessage: 'Linkedin profili dei founder e key people del team: ',
  },
  main_competitor: {
    id: `${scope}.main_competitor.message`,
    defaultMessage: 'Main Competitor: ',
  },
  mercato_di_riferimento: {
    id: `${scope}.mercato_di_riferimento.message`,
    defaultMessage: 'Mercato di riferimento: ',
  },
  partnership_validanti: {
    id: `${scope}.partnership_validanti.message`,
    defaultMessage: 'Partnership validanti: ',
  },
  prodotto_servizio: {
    id: `${scope}.prodotto_servizio.message`,
    defaultMessage: 'Prodotto Servizio: ',
  },
  revenue_model: {
    id: `${scope}.revenue_model.message`,
    defaultMessage: 'Revenue Model: ',
  },
  storia_di_lavoro_congiunta_dei_fondatori_e_capacita_di_gestire_conflitti_potenziali:
    {
      id: `${scope}.storia_di_lavoro_congiunta_dei_fondatori_e_capacita_di_gestire_conflitti_potenziali.message`,
      defaultMessage:
        'Storia di lavoro congiunta dei fondatori e capacita di gestire conflitti potenziali: ',
    },
  storia_ed_esperienza_dei_fondatori: {
    id: `${scope}.storia_ed_esperienza_dei_fondatori.message`,
    defaultMessage: 'Storia ed esperienza dei fondatori: ',
  },
  struttura_della_proprieta_e_dei_soci_prevalenti: {
    id: `${scope}.struttura_della_proprieta_e_dei_soci_prevalenti.message`,
    defaultMessage: 'Struttura della proprieta e dei soci prevalenti: ',
  },
  supply_chain_fornitori: {
    id: `${scope}.supply_chain_fornitori.message`,
    defaultMessage: 'Supply Chain Fornitori: ',
  },
  utenti_clienti: {
    id: `${scope}.utenti_clienti.message`,
    defaultMessage: 'Utenti Clienti: ',
  },
  situazione_debitoria_e_patrimoniale_aggiornata: {
    id: `${scope}.situazione_debitoria_e_patrimoniale_aggiornata.message`,
    defaultMessage: 'Situazione debitoria e patrimoniale aggiornata: ',
  },
  fatturato_prospettico_dell_anno_in_corso: {
    id: `${scope}.fatturato_prospettico_dell_anno_in_corso.message`,
    defaultMessage: 'Fatturato prospettico dell anno in corso: ',
  },
  fatturato_attuale: {
    id: `${scope}.fatturato_attuale.message`,
    defaultMessage: 'Fatturato attuale (YTD): ',
  },
});
