/**
 *
 * ApplyCompanyProfilePreview
 *
 */

import { Grid } from '@mui/material';
import { Typography } from '@mamacrowd/ui-kit';
import FormattedMessage from 'components/FormattedMessage';
import { ApplyCompanyProfile } from 'types/Application';
import messages from './messages';

export interface ApplyCompanyProfilePreviewProps {
  companyProfile?: ApplyCompanyProfile;
}

export function ApplyCompanyProfilePreview({
  companyProfile,
}: ApplyCompanyProfilePreviewProps) {
  return companyProfile ? (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="companyName"
            values={{ company: companyProfile.name.toLowerCase() }}
            isHtml
          />
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <FormattedMessage
            messages={messages}
            messageId="vatNumber"
            values={{ vatNumber: companyProfile.VATNumber }}
            isHtml
          />
        </Typography>
      </Grid>
    </Grid>
  ) : null;
}

export default ApplyCompanyProfilePreview;
