/*
 * ApplyCompanyProfilePreview Messages
 *
 * This contains all the text for the ApplyCompanyProfilePreview component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ApplyCompanyProfilePreview';

export default defineMessages({
  companyName: {
    id: `${scope}.company_name.message`,
    defaultMessage: 'Società: <b>{company}</b>',
  },
  vatNumber: {
    id: `${scope}.vat_number.message`,
    defaultMessage: 'P.IVA: <b>{vatNumber}</b>',
  },
});
