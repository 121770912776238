import { makeStyles } from '@mui/styles';
import { MAX_WIDTH_PAGE } from 'utils/constants';

export const useStyle = makeStyles(theme => ({
  headerContainer: {
    backgroundColor:
      process.env.SERVER_BE === 'staging'
        ? theme.palette.common.bgHeaderDev
        : theme.palette?.background?.default || theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    boxShadow: 'none',
    zIndex: 5,
  },
  headerItemsContainer: {
    width: '100%',
    height: 60,
    maxWidth: MAX_WIDTH_PAGE,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      height: 90,
    },
  },
  header: {
    width: '100%',
    height: 60,
    [theme.breakpoints.up('md')]: {
      height: 90,
    },
  },
  desktopLogo: {
    flex: '0 0 120px',
  },
  menuIcon: {
    width: 30,
    height: 30,
    color: theme.palette.common.darkGray,
  },
  menuMobile: props => ({
    position: 'fixed',
    top: 60,
    left: 0,
    right: 0,
    height:
      props.accountIsOpened || props.hamburgerIsOpened
        ? 'calc(100vh - 60px)'
        : 0,
    overflow:
      props.accountIsOpened || props.hamburgerIsOpened ? 'hidden' : 'auto',
    backgroundColor: theme.palette.common.white,
    zIndex: 5,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    transition: 'height .3s',
    '-webkit-transition': 'height .3s',
  }),
  localeItem: {
    marginLeft: 'auto',
    maxWidth: 85,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 50,
    },
  },
}));
